import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Row, Col, Input, Spin, Form, message, Tag, Tooltip, Timeline, Radio } from "antd"

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import {editFactor, fetchFactor, getFactor} from "../../../../api/factor";
import {checkSqlQuery} from "../../../../api/maxbonus";
import NotFound from "../../../NotFound";
import useSelector from "../../../../shared/hooks/useSelector"
import { strings } from "utils/localization";

const { TextArea } = Input;

interface FactorProps { history: any, idProblem: any}
interface CheckSql {
  icon: string
  type: 'primary' | 'danger'
  tooltip: string
  loading: boolean
}

const replaceStr: any = (str: string) => {
  return str.replace(/!datebegin/g, '2020-01-01').replace(/!dateend/g, '2020-05-01')
}

const FactorForm = Form.create({ name: "factor" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {factor, factors} : any = props;

  const [loading, setLoading] = useState(false);
  const [formula, setFormula] = useState(factor.data.formula);
  const [cursorPosition, setCursorPosition] = useState( formula.length);
  const [checkSql, setCheckSql] = useState<CheckSql>({
    icon: 'monitor',
    type: "primary",
    tooltip: "Проверить валидность запроса ",
    loading : false
  });
  const [sqlResult, setSqlResult] = useState('');

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        
        checkSqlQuery(replaceStr(form.getFieldValue('sql')))
        .then((res: any) => {
          message.success('Запрос верный!');

          setCheckSql({
            icon: 'check',
            type: "primary",
            tooltip: "Запрос верный",
            loading: false
          })
          
          if (!err) {
            setLoading(true);
            const payload = {
              factor_name: values.name,
              var_name: values.var,
              formula: formula,
              sql_query: values.sql,
              factor_type: values.factor_type
            };

            editFactor(factor.data.id_factor, payload)
              .then((res) => {
                message.success('Данные успешно изменены');
                setLoading(false);
                window.history.back()
              })
              .catch((err) => {
                message.error('Ошибка изменения данных');
                setLoading(false)
              })
          }
        })
        .catch((err) => {
          message.error('Запрос не валидный');
          setSqlResult('')
          setCheckSql({
            icon: 'warning',
            type: "danger",
            tooltip: "Запрос не валидный! Проверить еще раз",
            loading: false
          })
        })
      })
    },
    [form, dispatch, formula, loading]
  )

  const genFormula = useCallback((e) => {
    const newFormula = `${formula.slice(0,cursorPosition)}${e}${formula.slice(cursorPosition,formula.length)}`;
    setFormula(newFormula)
  },[formula, cursorPosition]);

  const checkValidateSql = useCallback(() => {
    setCheckSql({...checkSql, loading: true})
    //const isValid = form.getFieldValue('sql').toUpperCase().slice(0,6) === 'SELECT'
   

    checkSqlQuery(replaceStr(form.getFieldValue('sql')))
    .then((res: any) => {
      const isValid = res.result[0].partner_id && res.result[0].value
      const strRes = JSON.stringify(res.result)
      const maxChar = 500
      
      strRes.length > maxChar ?
        setSqlResult(`${strRes.slice(0,maxChar)}... \b [всего ${maxChar} символов из ${strRes.length}]`)
        : setSqlResult(strRes)

      if (isValid) {
        message.success('Запрос верный!');

        setCheckSql({
          icon: 'check',
          type: "primary",
          tooltip: "Запрос верный",
          loading: false
        })
      }else{
        message.warning('Запрос валидный, но не верный!');

        setCheckSql({
          icon: 'warning',
          type: "primary",
          tooltip: "Запрос валидный, но не верный! Проверить еще раз",
          loading: false
        })
      }
    })
    .catch((err) => {
      message.error('Запрос не валидный');
      setSqlResult('')
      setCheckSql({
        icon: 'warning',
        type: "danger",
        tooltip: "Запрос не валидный! Проверить еще раз",
        loading: false
      })
    })
    
  },[checkSql]);

  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.name} className={styles.name} >
            {getFieldDecorator("name", {
              initialValue: factor.data.factor_name,
              rules: [
                { required: true, message: strings.factorName },
              ],
            })(
              <Input
                placeholder={strings.name}
              />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={strings.operand} className={styles.name} >
            {getFieldDecorator("var", {
              initialValue: factor.data.var_name,
              rules: [
                { required: true, message: strings.operand },
              ],
            })(
              <Input
                placeholder={strings.operand}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.formula} className={styles.name} >
            <Input
              defaultValue={formula}
              value={formula}
              onChange={(e: any) => setFormula(e.target.value)}
              onBlur={(e) => setCursorPosition(e.target.selectionStart)}
              placeholder={strings.formula}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={strings.factorType} className={styles.name} >
            {getFieldDecorator("factor_type", {
              initialValue: factor.data.factor_type,
              rules: [
                { required: true, message: strings.factorType },
              ],
            })(
              <Radio.Group>
                {config.list_factor_type.map((item: any) => <Radio key={item.id} value={item.id}>{item.title}</Radio>)}
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.factors} >
            {factors.map((item: any) =>
              <Tag
                key={item.id_factor}
                onClick={() => genFormula(item.var_name)}
              >
                {`${item.factor_name} (${item.var_name})`}
              </Tag>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item 
            label={
              <>
                <Tooltip title={checkSql.tooltip}>
                  <Button type={checkSql.type} loading={checkSql.loading} block size="small" icon={checkSql.icon} onClick={() => checkValidateSql()}/>
                </Tooltip>
                {` ${strings.sqlQuery}`}
              </>
            } 
            className={styles.name} >
              {getFieldDecorator("sql", {
                initialValue: factor.data.sql_query,
              })(
                <TextArea
                  rows={5}
                  placeholder={strings.sqlQuery}
                />
              )}
            </Form.Item>
        </Col>
        <Col>
          <Form.Item label={strings.sqlQueryAnnotation}>
            <ul style={{fontStyle: 'italic', listStyle: 'circle'}}>
              <li><span>{strings.sqlQueryMinParams}</span></li>
              <li><span>{strings.sqlQueryTemplates}</span></li>
              <li><span>{strings.sqlQueryButton} <Button type={checkSql.type} loading={checkSql.loading} block size="small" icon={checkSql.icon} onClick={() => checkValidateSql()}/></span></li>
            </ul>
          </Form.Item> 
        </Col>
        <Col>
        {sqlResult &&
          <Form.Item label="Результат ответа">
            <TextArea
              readOnly
              value={sqlResult}
              cols={100}
              rows={4}
              placeholder={strings.sqlQuery}
            />
          </Form.Item>
        }
        </Col>
      </Row>
      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SingleFactor = (props: any) => {

  const [factor, setFactor] = useState({});
  const [factors, setFactors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idFactor, setCurrentFactor} = props;

    getFactor(idFactor, {})
      .then((res: any) => {
        setFactor(res.data);
        setCurrentFactor(res.data)
        fetchFactor().then((res) => {
          const {dataModels} = res.data.data;

          setLoading(false)
          setFactors(dataModels);
        })
      } )
      .catch(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });

  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <FactorForm {...props} factor={factor} factors={factors} />
  )
}

export default SingleFactor
