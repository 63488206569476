import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Select, Tree, Input, Row, Form, Icon, Card, Col, Button, Spin, Modal, Divider} from "antd"

import _ from "lodash"

import FormulaConstructor from "./components/FormulaConstructor/index"

import useSelector from "../../../../shared/hooks/useSelector"

import {addTrigger, deleteTrigger, editTrigger} from "../../../../api/problem";
import { strings } from "utils/localization"


interface ProblemProps { history: any, idProblem: any}

const InputGroup = Input.Group;
const { Option } = Select;
const { TreeNode } = Tree;

const Triggers = (props: any) => {

  const isLoading = useSelector(({ config }) => config.loading)
  const config = useSelector(({ config }) => config)
  const defaultClusterNumber = -1;

  const {problem, formula, setFormula, tree, setTree, formulaRemoval, setFormulaRemoval, treeRemoval, setTreeRemoval} : any = props;

  const [rowNewTrigger, setRowNewTrigger] = useState({
    problem_id_problem: problem.data.id_problem,
    factor_id_factor: 0,
    condition_type_id_condition_type: 0,
    condition_value: '',
    period_count: '',
    period_type_id_period_type: 0,
    period_link_type_id_period_link_type: 0,
    unit_type_id_unit_type: 0,
    cluster_id_cluster: null
  });

  const [key, setKey] = useState(1);
  const [editable, setEditable] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [triggers, setTriggers] = useState(problem.data.problem_triggers);

  const validate = (row: any) => {
    return row.condition_type_id_condition_type === 3 ?
      !row.factor_id_factor ||
      !row.condition_type_id_condition_type ||
      !row.period_count ||
      !row.period_type_id_period_type ||
      !row.period_link_type_id_period_link_type
    :
      !row.factor_id_factor ||
      !row.condition_type_id_condition_type ||
      !row.condition_value ||
      !row.period_count ||
      !row.period_type_id_period_type ||
      !row.period_link_type_id_period_link_type ||
      !row.unit_type_id_unit_type;
  };

  const generateName = (row: any) => {
    return row.condition_type_id_condition_type === 3 ? 
      `${row.factor_id_factor === defaultClusterNumber ? _.find(config.list_cluster, {id_cluster: row.cluster_id_cluster}).cluster_name : _.find(config.list_factor, {id_factor: row.factor_id_factor}).factor_name } ${_.find(config.list_condition, {id_condition_type: row.condition_type_id_condition_type}).name.toLowerCase()} ${row.period_count} ${_.find(config.list_period, {id_period_type: row.period_type_id_period_type}).period_name.toLowerCase()} ${_.find(config.list_period_link, {id_period_link_type: row.period_link_type_id_period_link_type}).name.toLowerCase()}`
    : 
      `${row.factor_id_factor === defaultClusterNumber ? _.find(config.list_cluster, {id_cluster: row.cluster_id_cluster}).cluster_name : _.find(config.list_factor, {id_factor: row.factor_id_factor}).factor_name } ${_.find(config.list_condition, {id_condition_type: row.condition_type_id_condition_type}).name.toLowerCase()} на ${row.condition_value}${_.find(config.list_unit, {id_unit_type: row.unit_type_id_unit_type}).char} ${row.period_count} ${_.find(config.list_period, {id_period_type: row.period_type_id_period_type}).period_name.toLowerCase()} ${_.find(config.list_period_link, {id_period_link_type: row.period_link_type_id_period_link_type}).name.toLowerCase()}`;
  };

  const updateExistTrigger = useCallback((key, e, param) => {
    const newTriggers : any = triggers;

    setEditable(Math.random);
    newTriggers[key] = {...newTriggers[key], [param]: e, editable: true};
    
    setTriggers(newTriggers)

  },[triggers, editable]);

  const addCurrentTrigger = useCallback(() => {

    const notValidate = validate(rowNewTrigger);

    setHasError(notValidate);

    if (!notValidate) {
      setLoading(true);
      const newData = {...rowNewTrigger, trigger_var_name: generateName(rowNewTrigger)};
      if (rowNewTrigger.condition_type_id_condition_type === 3) {
        newData.condition_value = "0"
        newData.unit_type_id_unit_type = 3
      }

      addTrigger(newData)
        .then((res: any) => {
          setTriggers([...triggers, res.data.data])
          setLoading(false)
        })
        .catch((err) => setLoading(false))
    }else {
      setTimeout(() => {
        setHasError(false)
      }, 2000);
    }
    }, [triggers, rowNewTrigger, loading]);

  const editCurrentTrigger = useCallback((e) => {
    //console.log(e)

    const notValidate = validate(triggers[e]);

    let newTriggers = triggers;
    newTriggers[e] = {...triggers[e], hasError: notValidate};
    setTriggers(newTriggers);
    setEditable(Math.random);

    if (!notValidate) {
      setLoading(true);
      const newData = {...triggers[e], trigger_var_name: generateName(triggers[e])};
      editTrigger(triggers[e].id_problem_trigger, newData)
        .then((res: any) => {
          const newTriggers: any = triggers;

          newTriggers[e] = res.data.data;
          setTriggers(newTriggers);

          setLoading(false)
        })
        .catch((err) => setLoading(false))
    }else {
      setTimeout(() => {
        newTriggers[e] = {...triggers[e], hasError: false};
        setTriggers(newTriggers);
      }, 2000);
    }
  },[triggers, editable]);

  const deleteCurrentTrigger = useCallback((e) => {
    setLoading(true)
    deleteTrigger(e)
      .then(() => {
        setTriggers(_.filter(triggers, (item) => {
          return item.id_problem_trigger !== e
        }))
        setLoading(false)
      })
      .catch((err) => setLoading(false))
  },[triggers]);

  useEffect(() => {
    setKey(Math.random)
  }, [triggers, editable, loading]);

  return (
    <Spin spinning={loading}>
      <Row>
      <Form.Item label={strings.conditionProblemExist}>
        <Button type='link' onClick={() => setShowModal(true)}>{strings.triggerControl}</Button>
        <FormulaConstructor formula={formula} setFormula={setFormula} tree={tree} setTree={setTree} editable={editable} triggers={triggers} key={key} />
      </Form.Item>
      </Row>
      <Row>
      <Form.Item label={strings.conditionProblemDisappear}>
        <Button type='link' onClick={() => setShowModal(true)}>{strings.triggerControl}</Button>
        <FormulaConstructor formula={formulaRemoval} setFormula={setFormulaRemoval} tree={treeRemoval} setTree={setTreeRemoval} editable={editable} triggers={triggers} key={key} />
      </Form.Item>
      </Row>
      <Modal
          title={strings.triggerControl}
          visible={showModal}
          width='80%'
          onCancel={() => setShowModal(false)}
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={() => setShowModal(false)}>
              Ок
            </Button>
          ]}
        >
        <Row>
          <Form.Item validateStatus={hasError ? "error" : "validating"} style={{marginBottom: 0}}>
            <Row type="flex" justify="start" gutter={4}> 
              <Col>
                <Select onChange={(e: number) => setRowNewTrigger({...rowNewTrigger, factor_id_factor: e})} placeholder={strings.factor} style={{ minWidth: 200 }}>
                  {config.list_factor.map((item: any) =>
                    <Option key={item.id_factor} value={item.id_factor}>{item.factor_name}</Option>
                  )}
                </Select>
              </Col>
              {rowNewTrigger.factor_id_factor === defaultClusterNumber &&
                <Col>
                  <Select onChange={(e: any) => setRowNewTrigger({...rowNewTrigger, cluster_id_cluster: e})} placeholder="Кластер" style={{ minWidth: 100 }}>
                    {config.list_cluster.map((item: any) =>
                      <Option key={item.id_cluster} value={item.id_cluster}>{item.cluster_name}</Option>
                    )}
                  </Select>
                </Col>
              }
              <Col>
                <Select onChange={(e: number) => setRowNewTrigger({...rowNewTrigger, condition_type_id_condition_type: e})} placeholder={strings.behavior} style={{ minWidth: 150 }}>
                  {config.list_condition.map((item: any) =>
                    <Option key={item.id_condition_type} value={item.id_condition_type}>{item.name}</Option>
                  )}
                </Select>
              </Col>
              {rowNewTrigger.condition_type_id_condition_type !== 3 &&
                <>
                  <Col>
                    <Input onChange={(e: any) => setRowNewTrigger({...rowNewTrigger, condition_value: e.target.value})} style={{ width: 120 }} prefix={strings.on} placeholder={strings.value} defaultValue="" />
                  </Col>
                  <Col>
                    <Select onChange={(e: number) => setRowNewTrigger({...rowNewTrigger, unit_type_id_unit_type: e})} placeholder={strings.unit} style={{ minWidth: 100 }}>
                      {config.list_unit.map((item: any) =>
                        <Option key={item.id_unit_type} value={item.id_unit_type}>{item.char}</Option>
                      )}
                    </Select>
                  </Col>
                  </>
              }
              <Col>
                <Input onChange={(e: any) => setRowNewTrigger({...rowNewTrigger, period_count: e.target.value})} style={{ width: 'auto' }} placeholder={strings.count} defaultValue="" />
              </Col>
              <Col>
                <Select onChange={(e: number) => setRowNewTrigger({...rowNewTrigger, period_type_id_period_type: e})} placeholder={strings.periodType} style={{ minWidth: 150 }}>
                  {config.list_period.map((item: any) =>
                    <Option key={item.id_period_type} value={item.id_period_type}>{item.period_name}</Option>
                  )}
                </Select>
              </Col>
              <Col>
                <Select onChange={(e: number) => setRowNewTrigger({...rowNewTrigger, period_link_type_id_period_link_type: e})} placeholder={strings.periodLink} style={{ minWidth: 150 }}>
                  {config.list_period_link.map((item: any) =>
                    <Option key={item.id_period_link_type} value={item.id_period_link_type}>{item.name}</Option>
                  )}
                </Select>
              </Col>
              <Col>
                <Button type="primary" icon="plus" loading={loading} onClick={() => addCurrentTrigger()}>{strings.add}</Button>
              </Col>
            </Row>
            <Divider />
          </Form.Item>
          <Row gutter={16}>
          {triggers.map((valTrigger: any, key: number) =>
            <Col span={8} key={key}>
              <Card 
                title={`${strings.trigger} №${key+1}`}
                style={{ marginTop: 10 }}
                actions={valTrigger.editable ? [
                    <Button type="danger" icon="delete" loading={loading} onClick={() => deleteCurrentTrigger(valTrigger.id_problem_trigger)}>{strings.delete}</Button>,
                    <Button type="primary" icon="check" loading={loading} onClick={() => editCurrentTrigger(key)}>Изменить</Button>,
                  ]:[
                    <Button type="danger" icon="delete" loading={loading} onClick={() => deleteCurrentTrigger(valTrigger.id_problem_trigger)}>{strings.delete}</Button>
                  ]
                }
              >
                <Form.Item validateStatus={valTrigger.hasError ? "error" : "validating"} key={valTrigger.id_problem_trigger}  >
                  <Row type="flex" justify="start" gutter={4}> 
                    {triggers[key].cluster_id_cluster ?
                      <>
                        <Col span={12}>
                          <Select onChange={(e: number) => {
                            triggers[key] = {...triggers[key], 'cluster_id_cluster': e === defaultClusterNumber ? config.list_cluster[0].id_cluster : null};
                            updateExistTrigger(key, e, 'factor_id_factor')
                          }} defaultValue={valTrigger.factor_id_factor} placeholder={strings.factor} style={{ width: '100%' }}>
                            {config.list_factor.map((item: any) =>
                              <Option key={item.id_factor} value={item.id_factor}>{item.factor_name}</Option>
                            )}
                          </Select>
                        </Col>
                        <Col span={12}>
                          <Select onChange={(e: any) => updateExistTrigger(key, e, 'cluster_id_cluster')} defaultValue={valTrigger.cluster_id_cluster} placeholder="Кластер" style={{ width: '100%' }}>
                            {config.list_cluster.map((item: any) =>
                              <Option key={item.id_cluster} value={item.id_cluster}>{item.cluster_name}</Option>
                            )}
                          </Select>
                        </Col>
                      </>
                      :
                      <Col span={24}>
                        <Select onChange={(e: number) => {
                          triggers[key] = {...triggers[key], 'cluster_id_cluster': e === defaultClusterNumber ? config.list_cluster[0].id_cluster : null};
                          updateExistTrigger(key, e, 'factor_id_factor')
                        }} defaultValue={valTrigger.factor_id_factor} placeholder={strings.factor} style={{ width: '100%' }}>
                          {config.list_factor.map((item: any) =>
                            <Option key={item.id_factor} value={item.id_factor}>{item.factor_name}</Option>
                          )}
                        </Select>
                      </Col>
                    }
                    <Col span={24} style={{textAlign: 'center'}}><Divider><Icon type="down" /></Divider></Col>
                    <Col span={24}>
                      <Select onChange={(e: number) => updateExistTrigger(key, e, 'condition_type_id_condition_type')} defaultValue={valTrigger.condition_type_id_condition_type} placeholder={strings.behavior} style={{ width: '100%' }}>
                        {config.list_condition.map((item: any) =>
                          <Option key={item.id_condition_type} value={item.id_condition_type}>{item.name}</Option>
                        )}
                      </Select>
                    </Col>
                    {valTrigger.condition_type_id_condition_type !== 3 &&
                      <>
                        <Col span={24} style={{textAlign: 'center'}}><Divider><Icon type="down" /></Divider></Col>
                        <Col span={12}>
                          <Input onChange={(e: any) => updateExistTrigger(key, e.target.value, 'condition_value')} style={{ width: '100%' }} prefix={strings.on} placeholder={strings.value} defaultValue={valTrigger.condition_value} />
                        </Col>
                        <Col span={12}>
                          <Select onChange={(e: number) => updateExistTrigger(key, e, 'unit_type_id_unit_type')} defaultValue={valTrigger.unit_type_id_unit_type} placeholder={strings.unit} style={{ width: '100%' }}>
                            {config.list_unit.map((item: any) =>
                              <Option key={item.id_unit_type} value={item.id_unit_type}>{item.char}</Option>
                            )}
                          </Select>
                        </Col>
                      </> 
                    }
                    <Col span={24} style={{textAlign: 'center'}}><Divider><Icon type="down" /></Divider></Col>
                    <Col span={12}>
                      <Input onChange={(e: any) => updateExistTrigger(key, e.target.value, 'period_count')} style={{ width: '100%' }} placeholder={strings.count} defaultValue={valTrigger.period_count} />
                    </Col>
                    <Col span={12}>
                      <Select onChange={(e: number) => updateExistTrigger(key, e, 'period_type_id_period_type')} defaultValue={valTrigger.period_type_id_period_type} placeholder={strings.periodType} style={{ width: '100%' }}>
                        {config.list_period.map((item: any) =>
                          <Option key={item.id_period_type} value={item.id_period_type}>{item.period_name}</Option>
                        )}
                      </Select>
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}><Divider><Icon type="down" /></Divider></Col>
                    <Col span={24}>
                      <Select onChange={(e: number) => updateExistTrigger(key, e, 'period_link_type_id_period_link_type')} defaultValue={valTrigger.period_link_type_id_period_link_type} placeholder={strings.periodType} style={{ width: '100%' }}>
                        {config.list_period_link.map((item: any) =>
                          <Option key={item.id_period_link_type} value={item.id_period_link_type}>{item.name}</Option>
                        )}
                      </Select>
                    </Col>
                    {/* {valTrigger.editable && <Col><Button type="primary" icon="check" loading={loading} onClick={() => editCurrentTrigger(key)}/></Col> }
                    <Col>
                      <Button type="danger" icon="delete" loading={loading} onClick={() => deleteCurrentTrigger(valTrigger.id_problem_trigger)}/>
                    </Col> */}
                  </Row>
                </Form.Item>
              </Card>
            </Col>
          )}
          </Row>
        </Row>            
      </Modal>
    </Spin>
  )
}

export default Triggers
