import React from "react";

import "moment/locale/ru";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data, title }) => {

  return (
    <ExcelFile hideElement={true} filename={`Отчет по фактору ${title}`}>
      <ExcelSheet
        dataSet={data}
        name={title}
      >
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportExcel;
