import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Button, Tree, Skeleton, Spin } from "antd"

import Page from "shared/components/Page"
import {editProblemAction, fetchProblemAction} from "../../../../redux/problem/actions"
import {useDispatch} from "react-redux";
import {editProblem, fetchProblem, getProblem} from "../../../../api/problem";
import {loadState} from "../../../../utils/localStorage";
import CustomTree from "./CustomTree";
import {fetchPullRequest} from "../../../../api/pullRequest";

interface ProblemProps { history: any}

const { TreeNode } = Tree;

function ListPullRequest({...props}: ProblemProps): ReactElement {

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [pullRequests, setPullRequests] = useState([]);

  useEffect(() => {
    fetchPullRequest().then((res: any) => {
      const {data} = res.data;

      let resultState = data;

      data.forEach(function(context: any, idContext: any) {
        const newState = Object.assign({}, context.data)

        context.data.forEach(function (item: any, i: any) {
          newState[i] = {
            ...item,
            isLeaf: item.has_child_pull_request <= 0,
            title: item.button_text,
            key: `${item.id_pull_request}`
          }
        });

        resultState[idContext] = {...resultState[idContext], data: Object.values(newState)};
      })

      setPullRequests(resultState);
      setLoading(false);
    })

  },[]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    loading
      ? <Skeleton active />
      : pullRequests.map((item:any) => item.data.length > 0 &&
      <CustomTree key={item.context} context={item.context} history={history} contextPullRequests={item.data} contextLoading={loading}/>
    )
  )
}

export default ListPullRequest
