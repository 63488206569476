import client from "./client"


export function fetchPushTemplate(): Promise<any>{
  return client.get("push-templates")
}

export function getPushTemplate(id: string, payload: object): Promise<null>{
  return client.get(`push-template/${id}`)
}

export function addPushTemplate(payload: object): Promise<null> {
  return client.post("/push-template", payload)
}

export function editPushTemplate(id: string, payload: object): Promise<null> {
  return client.put(`/push-template/${id}`, payload)
}

export function deletePushTemplate(id: string): Promise<null> {
  return client.delete(`/push-template/${id}`)
}