import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Row, Col, Input, Spin, Form, message, Tag, Select, Descriptions, Comment, Tooltip} from "antd"

import moment from "moment";
import _ from "lodash";

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import {editSupport, getSupport} from "../../../../api/support";
import NotFound from "../../../NotFound";
import useSelector from "../../../../shared/hooks/useSelector"
import { strings } from "utils/localization";

const { Option } = Select;

const SupportForm = Form.create({ name: "context" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {support} : any = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          const payload = {
            comment: values.comment,
            status: values.status
          };

          editSupport(support.data.id_support, payload)
            .then((res) => {
              message.success('Данные успешно изменены');
              setLoading(false);
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, loading]
  )

  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.clientQuestion} className={styles.name} >
            <Comment
              className={styles.comment} 
              actions={support.data.chat_id && [<span>{support.data.chat_id}</span>]}
              author={_.find(config.list_partner, {id_partner: support.data.partner_id_partner}).name}
              content={
                <p>
                  {support.data.text}
                </p>
              }
              datetime={
                <Tooltip title={moment(support.data.created_at, "YYYY-MM-DD HH:mm").format('DD.MM.YYYY HH:mm')}>
                  <span>{moment(support.data.created_at, "YYYY-MM-DD HH:mm").format('DD.MM.YYYY HH:mm')}</span>
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.comment} className={styles.name} >
            {getFieldDecorator("comment", {
              initialValue: support.data.comment,
              rules: [
                { required: true, message: "Комментарий к вопросу" },
              ],
            })(
              <Input
                placeholder={strings.comment}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.status} className={styles.name} >
            {getFieldDecorator("status", {
              initialValue: support.data.status,
              rules: [
                { required: true, message: strings.status },
              ],
            })(
              <Select placeholder="Укажите статус">
                {
                  config.list_support_status_type.map((item: any) =>
                    <Option key={item.id} value={item.id}>{item.title}</Option>
                  )
                }
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SingleSupport = (props: any) => {

  const [support, setSupport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idSupport, setCurrentSupport} = props;

    getSupport(idSupport, {})
      .then((res: any) => {
        setSupport(res.data);
        setCurrentSupport(res.data)
        setLoading(false)
      } )
      .catch(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });

  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <SupportForm {...props} support={support} />
  )
}

export default SingleSupport
