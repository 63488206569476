import client from "./client"


export function fetchRecommendation(page: number): Promise<any>{
  return client.get(`recommendations?page=${page}`)
}

export function getRecommendation(id: string, payload: object): Promise<null>{
  return client.get(`recommendation/${id}`)
}

export function addRecommendation(payload: object): Promise<null> {
  return client.post("/recommendation", payload)
}

export function editRecommendation(id: string, payload: object): Promise<null> {
  return client.put(`/recommendation/${id}`, payload)
}

export function deleteRecommendation(id: string): Promise<null> {
  return client.delete(`/recommendation/${id}`)
}