import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Table, Tooltip, Button} from "antd"

import {fetchContext} from "../../../../api/context";
import { strings } from "utils/localization";

interface ContextProps { history: any}

function ListContext({...props}: ContextProps): ReactElement {

  const columns = [
    {
      dataIndex: 'id_context',
      render: (render: any) => (
        <Tooltip title="Редактирование">
          <Button
            type="link"
            onClick={e => history.push({pathname: `/context/${render}`})}
            shape="circle"
            icon="edit"
          />
        </Tooltip>
      )
    },
    {
      title: strings.name,
      dataIndex: 'context_name',
    },
    {
      title: strings.operand,
      dataIndex: 'var_name',
    }
  ];

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [contexts, setContexts] = useState([]);

  useEffect(() => {
    fetchContext().then((res) => {
      const {dataModels} = res.data.data;

      setContexts(dataModels);
      setLoading(false);
    })

  },[]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    <Table
      loading={loading}
      size="small"
      rowKey={(record: any) => record.id_context}
      columns={columns}
      dataSource={contexts}
    />
  )
}

export default ListContext
