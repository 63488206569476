import { Dispatch } from "redux"

import {
  fetchProblem,
  addProblem,
  editProblem,
  deleteProblem, getProblem,
} from "../../api/problem"

import {
  IProblemAction,
  PROBLEM_FAILURE,
  PROBLEM_REQUEST,
  PROBLEM_SUCCESS,
  PROBLEM_ADD,
  PROBLEM_DELETE,
  PROBLEM_EDIT,
} from "./types"
import {AxiosResponse} from "axios";

export const fetchProblemAction = () => (dispatch: Dispatch) => {
  dispatch<IProblemAction>({ type: PROBLEM_REQUEST })

  fetchProblem()
    .then((response: AxiosResponse)  =>
      dispatch<IProblemAction>({
        type: PROBLEM_SUCCESS,
        payload: response.data.data,
        total: response.data.total })
    )
    .catch(() => dispatch<IProblemAction>({ type: PROBLEM_FAILURE }))
}

export const addProblemAction = (payload: object) => (dispatch: Dispatch) => {
  dispatch<IProblemAction>({ type: PROBLEM_REQUEST })

  addProblem(payload)
    .then(response =>
      dispatch<IProblemAction>({ type: PROBLEM_ADD, payload: response.data })
    )
    .catch(() => dispatch<IProblemAction>({ type: PROBLEM_FAILURE }))
}

export const readProblemAction = (id: string, payload: object) => (dispatch: Dispatch) => {
  dispatch<IProblemAction>({ type: PROBLEM_REQUEST })

  getProblem(id, payload)
    .then(response =>
      dispatch<IProblemAction>({ type: PROBLEM_EDIT, payload: response.data })
    )
    .catch(() => dispatch<IProblemAction>({ type: PROBLEM_FAILURE }))
}

export const editProblemAction = (id: string, payload: object) => (dispatch: Dispatch) => {
  dispatch<IProblemAction>({ type: PROBLEM_REQUEST })

  editProblem(id, payload)
    .then(response =>
      dispatch<IProblemAction>({ type: PROBLEM_EDIT, payload: response.data })
    )
    .catch(() => dispatch<IProblemAction>({ type: PROBLEM_FAILURE }))
}

export const deleteProblemAction = (id: string) => (dispatch: Dispatch) => {
  dispatch<IProblemAction>({ type: PROBLEM_REQUEST })

  deleteProblem(id)
    .then(response =>
      dispatch<IProblemAction>({ type: PROBLEM_DELETE, payload: { id_problem: id } })
    )
    .catch(() => dispatch<IProblemAction>({ type: PROBLEM_FAILURE }))
}