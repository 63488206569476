import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Radio} from "antd"

import Page from "shared/components/Page"
import SingleFactor from "./screens/SingleFactor";
import ListFactor from "./screens/ListFactor";
import {addFactor, deleteFactor} from "../../api/factor";
import useSelector from "../../shared/hooks/useSelector"
import { strings } from "utils/localization";

interface FactorProps { history: any}

function Factor({...props}: FactorProps): ReactElement {

  const config = useSelector(({ config }) => config)
  
  const {pathname} = props.history.location;
  const idFactor = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [factorName, setFactorName] = useState('');
  const [factorVarName, setFactorVarName] = useState('');
  const [factorType, setFactorType] = useState(1);
  const [key, setKey] = useState(1);
  const [currentFactor, setCurrentFactor] = useState({data: {factor_name: ''}});

  const addNewFactor = useCallback(() => {

    setFactorName('');
    setFactorVarName('');
    setFactorType(1);

    setLoading(true);
    const payload = {
      factor_name: factorName,
      var_name: factorVarName,
      formula: factorVarName,
      factor_type: factorType
    };

    addFactor(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[factorName, factorVarName, key, visibleModal, loading]);

  const deleteCurrentFactor = useCallback(() => {

    setLoading(true);

    deleteFactor(idFactor)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idFactor]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idFactor,
          title: idFactor ? currentFactor.data.factor_name : strings.factors,
          actionButtons: !idFactor ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addFactor}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentFactor()}>
              {strings.removeFactor}
            </Button>,
          ],
        }}
      >
        {idFactor
          ?
            <SingleFactor {...props} idFactor={idFactor} setCurrentFactor={setCurrentFactor} />
          :
            <ListFactor key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedFactor}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!factorName || !factorVarName || !factorType)} onClick={() => addNewFactor()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.factorName}>
          <Input value={factorName} onChange={(e: any) => setFactorName(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.operand}>
          <Input value={factorVarName} onChange={(e: any) => setFactorVarName(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.factorType}>
          <Radio.Group value={factorType} onChange={(e: any) => setFactorType(e.target.value)}>
            {config.list_factor_type.map((item: any) => <Radio key={item.id} value={item.id}>{item.title}</Radio>)}
          </Radio.Group>
        </Form.Item>
      </Modal>
    </>
  )
}

export default Factor
