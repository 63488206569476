import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message} from "antd"

import Page from "shared/components/Page"
import SingleContext from "./screens/SingleContext";
import ListContext from "./screens/ListContext";
import {addContext, deleteContext} from "../../api/context";
import { strings } from "utils/localization";

interface ContextProps { history: any}

function Context({...props}: ContextProps): ReactElement {

  const {pathname} = props.history.location;
  const idContext = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contextName, setContextName] = useState('');
  const [contextVarName, setContextVarName] = useState('');
  const [key, setKey] = useState(1);
  const [currentContext, setCurrentContext] = useState({data: {context_name: ''}});

  const addNewContext = useCallback(() => {

    setContextName('');
    setContextVarName('');

    setLoading(true);
    const payload = {
      context_name: contextName,
      var_name: contextVarName
    };

    addContext(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[contextName, contextVarName, key, visibleModal, loading]);

  const deleteCurrentContext = useCallback(() => {

    setLoading(true);

    deleteContext(idContext)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idContext]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idContext,
          title: idContext ? currentContext.data.context_name : strings.contexts,
          actionButtons: !idContext ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addContext}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentContext()}>
              {strings.removeContext}
            </Button>,
          ],
        }}
      >
        {idContext
          ?
            <SingleContext {...props} idContext={idContext} setCurrentContext={setCurrentContext} />
          :
            <ListContext key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedContext}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!contextName || !contextVarName)} onClick={() => addNewContext()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.contextName}>
          <Input value={contextName} onChange={(e: any) => setContextName(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.operand}>
          <Input value={contextVarName} onChange={(e: any) => setContextVarName(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  )
}

export default Context
