import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Table, Tooltip, Button, Badge} from "antd"
import moment from "moment";

import {fetchSupport} from "../../../../api/support";
import useSelector from "../../../../shared/hooks/useSelector"

import _ from "lodash";
import { strings } from "utils/localization";

interface ContextProps { history: any}

function ListSupport({...props}: ContextProps): ReactElement {

  const config = useSelector(({ config }) => config)

  const columns = [
    {
      dataIndex: 'id_support',
      render: (render: any) => (
        <Tooltip title={strings.answer}>
          <Button
            type="link"
            onClick={e => history.push({pathname: `/support/${render}`})}
            shape="circle"
            icon="message"
          />
        </Tooltip>
      )
    },
    {
      title: strings.chat,
      dataIndex: 'chat_id',
    },
    {
      title: strings.partner,
      dataIndex: 'partner_id_partner',
      render: (render: any) => _.find(config.list_partner, {id_partner: render}).name
    },
    {
      title: strings.textQuestion,
      dataIndex: 'text',
    },
    {
      title: strings.dateCreate,
      dataIndex: 'created_at',
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    },
    {
      title: strings.dateChange,
      dataIndex: 'updated_at',
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    },
    {
      title: strings.status,
      dataIndex: 'status',
      render: (render: any) => 
        <>
          <Badge status={render === 1 ? 'processing' : 'success' } /> 
          {_.find(config.list_support_status_type, {id: render}).title}
        </>
    }
  ];

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [support, setSupport] = useState([]);
  const [pagination, setPagination] = useState({
    total: 10,
    page: 1
  });

  useEffect(() => {
    setLoading(true);
    fetchSupport(pagination.page).then((res) => {
      const {dataModels} = res.data.data;
      const {total} = res.data


      setSupport(dataModels);
      setPagination({...pagination, total: total});
      setLoading(false);
    })

  },[pagination.page]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    <Table
      loading={loading}
      size="small"
      rowKey={(record: any) => record.id_support}
      pagination={{
        total: pagination.total,
        current: pagination.page
      }}
      onChange={(e:any) => setPagination({...pagination, page: e.current})}
      columns={columns}
      dataSource={support}
    />
  )
}

export default ListSupport
