import React, {Component} from 'react';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import {Query, Builder, BasicConfig, Widgets, Utils as QbUtils} from 'react-awesome-query-builder';
import 'react-awesome-query-builder/css/antd.less';
//import "antd/dist/antd.css";
import 'react-awesome-query-builder/css/styles.scss';
import 'react-awesome-query-builder/css/compact_styles.scss'; //optional, for more compact styles
import { strings } from 'utils/localization';


// You can load query value from your backend storage (for saving see `Query.onChange()`)


class FormulaConstructor extends Component {

  constructor(props) {
    super(props);

    const {formula, setFormula, tree, setTree, triggers} = props;
    const queryValue = tree ? JSON.stringify(tree) :{"id": QbUtils.uuid(), "type": "group"};

    const config = {
      ...BasicConfig,
      conjunctions: {
        AND: {
          label: strings.and,
          formatConj: (children, _conj, not) => ( (not ? 'NOT ' : '') + '(' + children.join(' || ') + ')' ),
          reversedConj: 'OR',
          mongoConj: '$and',
        },
        OR: {
          label: strings.or,
          formatConj: (children, _conj, not) => ( (not ? 'NOT ' : '') + '(' + children.join(' || ') + ')' ),
          reversedConj: 'AND',
          mongoConj: '$or',
        },
      },
      operators:{
        ...BasicConfig.operators,
        is_not_empty: {
          label: strings.exist,
          labelForFormat: "IS NOT EMPTY",
          sqlOp: "IS NOT EMPTY",
          cardinality: 0,
          reversedOp: "is_empty",
          formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
          mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
          jsonLogic: "!!"
        },
        equal: {
          label: strings.availability,
          labelForFormat: "EQUAL",
          sqlOp: "EQUAL",
          cardinality: 1,
          reversedOp: "not_equal",
          formatOp: (field, _op, value, _valueSrc, _valueType, opDef) => `${field} ${opDef.labelForFormat} ${value}`,
          mongoFormatOp: (field, op, value) => ({ [field]: { '$eq': value } }),
          jsonLogic: "=="
        }
      },
      widgets: {
        ...BasicConfig.widgets,
        boolean: {
          ...BasicConfig.widgets.boolean,
          labelYes: strings.thereIs,
          labelNo: strings.absent,
      },
      },
      settings: {
        addRuleLabel: strings.addCondition,
        addGroupLabel: strings.createGroup,
        notLabel: strings.not,
        showNot: false,
        fieldPlaceholder: strings.selectTrigger,
        locale: {
          short: 'ru',
          full: 'ru-RU',
          antd: ru_RU,
        }
      }
    };
    
    const fields = {};
    triggers.map((item) => {
      fields[item.id_problem_trigger] ={
        label: item.trigger_var_name,
        type: 'boolean',
        operators: ['equal'],
        valueSources: ['value'],
      }
    });

    //console.log({...config, fields})

    this.state = {
      triggers: triggers,
      userTree: tree,
      setTree: setTree,
      formula: formula,
      setFormula: setFormula,
      tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), {...config, fields}),
      config: {...config, fields},
    };
  }

  render = () => (
    <div>
      <Query
        {...this.state.config}
        value={this.state.tree}
        onChange={this.onChange}
        renderBuilder={this.renderBuilder}
      />
      {/*{this.renderResult(this.state)}*/}
    </div>
  )

  renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  )

  renderResult = ({tree: immutableTree, config}) => (
    <div className="query-builder-result">
      <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
      <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div>
      <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
      <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
    </div>
  )

  onChange = (immutableTree, config) => {
    //console.log(this.state);
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({tree: immutableTree, config: config});

    //console.log(QbUtils.jsonLogicFormat(immutableTree, config).logic)
    const jsonTree = QbUtils.getTree(immutableTree);
    this.state.setFormula(QbUtils.jsonLogicFormat(immutableTree, config).logic);
    this.state.setTree(jsonTree);
    //console.log(jsonTree, JSON.stringify(jsonTree));
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }
}

export default FormulaConstructor
