import client from "./client"


export function fetchContext(): Promise<any>{
  return client.get("contexts")
}

export function getContext(id: string, payload: object): Promise<null>{
  return client.get(`context/${id}`)
}

export function addContext(payload: object): Promise<null> {
  return client.post("/context", payload)
}

export function editContext(id: string, payload: object): Promise<null> {
  return client.put(`/context/${id}`, payload)
}

export function deleteContext(id: string): Promise<null> {
  return client.delete(`/context/${id}`)
}