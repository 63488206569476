import { Dispatch } from "redux"

import { login } from "../../api/auth"
import { IAuthAction, IAuthState, LOGIN_SUCCESS, LOGIN, LOGIN_FAILURE, LOGOUT } from "./types"
import {  AxiosResponse } from "axios"

type LoginResponse = {
  data: {
    username: string
    email: string
    token: string
  }
}

type LoginError = {
  message: string
}

export const loginAction = (username: string, password: string) => (dispatch: Dispatch) => {
  dispatch({ type: LOGIN })
  login(username, password)
    .then((res: AxiosResponse<LoginResponse>) => {
      const { username, token } = res.data.data;

      dispatch(loginSuccessAction({ token, username }))
    })
    .catch(() => dispatch<IAuthAction>({ type: LOGIN_FAILURE }))
}

const loginSuccessAction = (payload: IAuthState): IAuthAction => ({
  type: LOGIN_SUCCESS,
  payload,
})

export const logoutAction = () => ({
  type: LOGOUT,
})
