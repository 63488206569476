import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Row, Col, Input, Spin, Form, message, Tag, TimePicker, Select} from "antd"

import {useDispatch} from "react-redux";
import useSelector from "../../../../shared/hooks/useSelector"
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import {editPushTemplate, fetchPushTemplate, getPushTemplate} from "../../../../api/pushTemplate";
import NotFound from "../../../NotFound";

import moment from "moment";
import "moment/locale/ru";
import { strings } from "utils/localization";

const { Option } = Select;

const PushTemplateForm = Form.create({ name: "push_template" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {pushTemplate, pushTemplates} : any = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          const payload = {
            time_start: moment(values.timeStart, 'HH:mm').format('HH:mm'),
            time_stop: moment(values.timeStop, 'HH:mm').format('HH:mm'),
            partner_id_partner: values.partner,
            context_id_context: values.context,
          };

          editPushTemplate(pushTemplate.data.id_push_template, payload)
            .then((res) => {
              message.success('Данные успешно изменены');
              setLoading(false);
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, loading]
  )

  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Form.Item label={strings.periodSend} className={styles.name}>
        {`${strings.from} `}
        {getFieldDecorator('timeStart', { 
              initialValue: moment(pushTemplate.data.time_start ? pushTemplate.data.time_start : '00:00', 'HH:mm'),
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Укажите время',
                  whitespace: true,
                },
              ],
          })(
          <TimePicker format='HH:mm' placeholder="Дата отправки" onChange={() => console.log(123)} />
        )} 
        {` ${strings.to} `} 
        {getFieldDecorator('timeStop', { 
              initialValue: moment(pushTemplate.data.time_stop ? pushTemplate.data.time_stop : '00:00', 'HH:mm'),
              rules: [
                {
                  type: 'object',
                  required: true,
                  message: 'Укажите время',
                  whitespace: true,
                },
              ],
          })(
          <TimePicker format='HH:mm' placeholder="Дата отправки" onChange={() => console.log(123)} />
        )} 
      </Form.Item>
      <Row type="flex" justify="start" gutter={32}>
        <Col span={12}>
          <Form.Item label={strings.partner} className={styles.name}>
          {getFieldDecorator("partner", {
              initialValue: pushTemplate.data.partner_id_partner,
              rules: [
                { required: true, message: strings.partner },
              ],
            })(
              <Select placeholder="Выберите партнера">
                {config.list_partner.map((item: any) => <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>)}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col span={12}>
          <Form.Item label={strings.context} className={styles.name}>
          {getFieldDecorator("context", {
              initialValue: pushTemplate.data.context_id_context,
              rules: [
                { required: true, message: strings.context },
              ],
            })(
              <Select placeholder="Выберите контекст">
                {config.list_context.map((item: any) => <Option key={item.id_context} value={item.id_context}>{item.context_name}</Option>)}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SinglePushTemplate = (props: any) => {

  const [pushTemplate, setPushRemplate] = useState({});
  const [pushTemplates, setPushRemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idPushTemplate, setCurrentPushTemplate} = props;

    getPushTemplate(idPushTemplate, {})
      .then((res: any) => {
        setPushRemplate(res.data);
        setCurrentPushTemplate(res.data)
        fetchPushTemplate().then((res) => {
          const {dataModels} = res.data.data;

          setLoading(false)
          setPushRemplates(dataModels);
        })
      } )
      .catch(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });

  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <PushTemplateForm {...props} pushTemplate={pushTemplate} pushTemplates={pushTemplates} />
  )
}

export default SinglePushTemplate
