import React from "react"

import Layout from "../Layout"
import Home from "screens/Home"
import Problems from "screens/Problems"
import Factors from "screens/Factors"
import History from "screens/History"
import HistoryPushRequest from "screens/HistoryPushRequest"
import Contexts from "screens/Contexts"
import Tags from "screens/Tags"
import PushTemplates from "screens/PushTemplates"
import PullRequests from "screens/PullRequests"
import PushRequests from "screens/PushRequests"
import Support from "screens/Support"
import RoutePage from '../RoutePage'
import Recommendaton from "screens/Recommendations"
import Chat from "screens/Chat"
import { strings } from "utils/localization"

function App() {
  return (
    <Layout>
      <RoutePage pageKey="home" label={strings.home} icon="home" component={Home} defaultPage/>
      <RoutePage pageKey="problems" label={strings.problems} icon="alert" component={Problems} defaultPage={false}/>
      <RoutePage pageKey="factors" label={strings.factor} icon="profile" component={Factors} defaultPage={false}/>
      <RoutePage pageKey="history" label={strings.problemHistory} icon="book" component={History} defaultPage={false}/>
      <RoutePage pageKey="context" label={strings.contexts} icon="gateway" component={Contexts} defaultPage={false}/>
      <RoutePage pageKey="history-push-requests" label={strings.pushHistory} icon="schedule" component={HistoryPushRequest} defaultPage={false}/>
      <RoutePage pageKey="push-template" label={strings.pushExamples} icon="layout" component={PushTemplates} defaultPage={false}/>
      <RoutePage pageKey="pull-requests" label={strings.answerChatbot} icon="message" component={PullRequests} defaultPage={false}/>
      <RoutePage pageKey="push-requests" label={strings.pushChatbot} icon="bell" component={PushRequests} defaultPage={false}/>
      <RoutePage pageKey="recommendation" label={strings.recommendations} icon="solution" component={Recommendaton} defaultPage={false}/>
      <RoutePage pageKey="tags" label={strings.tags} icon="tags" component={Tags} defaultPage={false}/>
      <RoutePage pageKey="support" label={strings.support} icon="question-circle" component={Support} defaultPage={false}/>
      <RoutePage pageKey="chat" label={strings.collaboration} icon="team" component={Chat} defaultPage={false}/>
    </Layout>
  )
}

export default App
