import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Table, Tooltip, Button} from "antd"

import {fetchRecommendation} from "../../../../api/recommendation";
import _ from "lodash";
import moment from "moment";
import { strings } from "utils/localization";

interface RecommendationProps { history: any}

function ListRecommendation({...props}: RecommendationProps): ReactElement {

  const {history} = props;

  const columns = [
    {
      dataIndex: 'id_recommendation_problem',
      render: (render: any) => (
        <Tooltip title="Редактирование">
          <Button
            type="link"
            onClick={e => history.push({pathname: `/recommendation/${render}`})}
            shape="circle"
            icon="edit"
          />
        </Tooltip>
      )
    },
    {
      title: strings.problem,
      dataIndex: 'problem_id_problem',
      render: (render: any, row: any) => (<Button style={{padding: 4}} type="link" onClick={() => gotoProblem(render)}>
        {row.problem_name}
      </Button>
      )
    },
    {
      title: strings.description,
      dataIndex: 'text',
    },
    {
      title: strings.horizonResult,
      dataIndex: 'day_count',
    },
    {
      title: strings.weight,
      dataIndex: 'weight',
    },
    {
      title: strings.dateCreate,
      dataIndex: 'created_at',
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    },
    {
      title: strings.dateChange,
      dataIndex: 'updated_at',
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    }
  ];

  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState([]);
  const [pagination, setPagination] = useState({
    total: 10,
    page: 1
  });

  const gotoProblem = React.useCallback((idProblem) => {
    const {history} = props;

    history.push({pathname: `/problems/${idProblem}`});
  },[]);

  useEffect(() => {
    setLoading(true);

    fetchRecommendation(pagination.page).then((res: any) => {
      const {data, total} = res.data;

      setRecommendations(data);
      setPagination({...pagination, total: total});
      setLoading(false);
    })

  },[pagination.page]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    <Table
      loading={loading}
      size="small"
      pagination={{
        total: pagination.total,
        current: pagination.page
      }}
      onChange={(e:any) => setPagination({...pagination, page: e.current})}
      rowKey={(record: any) => record.id_recommendation_problem}
      columns={columns}
      dataSource={recommendations}
    />
  )
}

export default ListRecommendation
