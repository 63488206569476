import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Row, Col, Input, Spin, Form, message, Select, InputNumber, Cascader, Icon} from "antd"

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import NotFound from "../../../NotFound";
import { editRecommendation, getRecommendation } from "api/recommendation";
import _ from "lodash";
import { strings } from "utils/localization";

const { Option, OptGroup } = Select;

const RecommendationForm = Form.create({ name: "recommendation" })((props) => {

  let id = 0;

  const dispatch = useDispatch()
  const {form} : FormComponentProps = props;
  const {recommendation} : any = props;
  const {problems} : any = props;
  const {config} : any = props;
  const defaultKeys = recommendation.data.factors.map((elem: any, key: any) => {return key});

  const { getFieldDecorator, getFieldValue } = form

  const [options, setOptions] = useState([]);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);

          let newPartners = [] as any
          values.partners.map((item: any, key: number) => newPartners[key] = {
            "id_partner": item
          })

          let newFactors = [] as any
          values.factors.map((item: any, key: number) => newFactors[key] = {
            "id_factor": item[0],
            "id_condition_type": item[1]
          })

          const clearFactors = newFactors.filter((el:any) => {
            return el != null;
          });

          const payload = {
            problem_id_problem: values.problem,
            text: values.text,
            day_count: values.dayCount,
            weight: values.weight,
            partners: newPartners,
            factors: clearFactors
          };

          editRecommendation(recommendation.data.id_recommendation_problem, payload)
            .then((res) => {
              message.success('Данные успешно изменены');
              setLoading(false);
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, loading]
  )

  useEffect(() => {
    const constructPartners = _.map(recommendation.data.partners, 'partner_id') as any
    setPartners(constructPartners)

    let constructOptions = [] as any
    let constructConditionOptions = [] as any

    config.list_condition.map((item: any, key: number) => constructConditionOptions[key] = {
      value: item.id_condition_type,
      label: item.name,
    })
    config.list_factor.map((item: any, key: number) => constructOptions[key] = {
      value: item.id_factor,
      label: item.factor_name,
      children: constructConditionOptions
    })
    setOptions(constructOptions)

    let defaultFactors = [] as any

    recommendation.data.factors.map((item: any,key: number) => {
      defaultFactors[key] = [item.factor_id, item.condition_type_id]
    })
    
    id = defaultFactors.length + 1

    form.setFieldsValue({
      factors: defaultFactors.length > 0 ? defaultFactors : [[]],
    });
    
  },[]);

  const add  = React.useCallback(() => {
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  },[])

  const remove  = React.useCallback(k => {
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key: any) => key !== k),
    });
  },[])

  getFieldDecorator('keys', { initialValue: defaultKeys.length > 0 ? defaultKeys : [0]});
  const keys = getFieldValue('keys');
  const formItems = keys.map((k: any, index: any) => (
    <Row key={k} type="flex" gutter={32}>
      <Col span={20}>
        {getFieldDecorator(`factors[${k}]`, {
          rules: [
            {
              required: true,
              message: "Поведения фактора",
            },
          ],
        })(<Cascader changeOnSelect options={options} placeholder="Определите поведение" />)}
      </Col>
      <Col span={1}>
        {keys.length > 1 ? (
          <Button size="small" type="danger" shape="circle" icon="delete" onClick={() => remove(k)} />
        ) : null}
      </Col>
    </Row>
  ));

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.problem} className={styles.name} >
            {getFieldDecorator("problem", {
              initialValue: recommendation.data.problem_id_problem,
              rules: [
                { required: true, message: strings.problem },
              ],
            })(
              <Select style={{width: '100%'}}>
                {problems.map((item: any) =>
                <OptGroup key={item.partner} label={_.find(config.list_partner, {id_partner: item.partner}).name}>
                  {item.data.map((itemProblem: any) => 
                    <Option key={itemProblem.id_problem} value={itemProblem.id_problem}>{itemProblem.problem_name}</Option>
                  )}
                  </OptGroup>
                )}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label="Партнеры" className={styles.name} >
            {getFieldDecorator("partners", {
              initialValue: partners,
              rules: [
                { required: true, message: "Партнеры" },
              ],
            })(
              <Select mode="multiple" style={{width: '100%'}}>
                  {config.list_partner.map((item: any) => 
                    <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>
                  )}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.horizonResult} className={styles.name} >
            {getFieldDecorator("dayCount", {
              initialValue: recommendation.data.day_count,
              rules: [
                { required: true, message: strings.horizonResult },
              ],
            })(
              <InputNumber 
                style={{width: '100%'}}
                min={0}
                placeholder={strings.horizonResult}
              />
            )}
          </Form.Item>
          <Form.Item label={strings.weight} className={styles.name} >
            {getFieldDecorator("weight", {
              initialValue: recommendation.data.weight,
              rules: [
                { required: true, message: strings.weight },
              ],
            })(
              <InputNumber 
                style={{width: '100%'}}
                min={0}
                max={100}
                placeholder={strings.weight}
              />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label={strings.impactOnFactors} className={styles.name}>
            {formItems}
            <Button type="dashed" onClick={(e) => add()} style={{ width: '100%' }}>
              <Icon type="plus" /> {strings.addFactor}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.textRecommendation} className={styles.name} >
            {getFieldDecorator("text", {
              initialValue: recommendation.data.text,
              rules: [
                { required: true, message: strings.textRecommendation },
              ],
            })(
              <Input
                placeholder={strings.textRecommendation}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SingleRecommendation = (props: any) => {

  const {idRecommendation, setCurrentRecommendation, problems, config} = props;

  const [recommendation, setRecommendation] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {

    getRecommendation(idRecommendation, {})
      .then((res: any) => {
        setRecommendation(res.data);
        setCurrentRecommendation(res.data)
        setLoading(false)
      } )
      .catch(() => setLoading(false))
      .catch((err) => {
        setError(true);
        setLoading(false)
      });

  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <RecommendationForm {...props} recommendation={recommendation} problems={problems} config={config} />
  )
}

export default SingleRecommendation
