export default function fetchData(url, method = "GET", body = null) {
  let config = {
    method,
    headers: {}
  };

  if (!body) body = {};

  body["niokr_token"] = 'SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'

  config.body = Object.entries(body)
    .map(e => e.join("="))
    .join("&");

  return fetch(url, config);
}
