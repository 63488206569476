import fetchData from "./fetchData";
import { parseJSON, checkHttpStatus } from "shared/utils";

export function checkSqlQuery(string: string): Promise<null>{
  const payload = {sql_query: string}
  return fetchData(
    `https://cabinet.maxbonus.ru/cloud/service.php?class=niokr&action=validate_sql_query`,
    "POST",
    payload
  )
    .then(parseJSON)
    .then(checkHttpStatus);
}
