import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tree, Input, Spin, Form, Tag, Col, Skeleton, message, Select, Row, Modal, DatePicker, Switch, TimePicker} from "antd"

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";

import NotFound from "../../../NotFound";
import useSelector from "../../../../shared/hooks/useSelector"
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from "moment";
import "moment/locale/ru";

import { fetchProblemAll} from "../../../../api/problem";
import {
  fetchAllPullRequest,
  fetchTags,
} from "../../../../api/pullRequest";

import {
  editPushRequest,
  getPushRequest
} from "../../../../api/pushRequest";
import { strings } from "utils/localization";

const { TextArea } = Input;
const { TreeNode } = Tree;
const { Option } = Select;

const PushRequestForm = Form.create({ name: "pullRequest" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {pushRequest} : any = props;

  const [allPullRequest, setAllPullRequest] = useState([]);
  const [loadingAllPullRequest, setLoadingAllPullRequest] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [paramType, setParamType] = useState('');
  const [paramValue, setParamValue] = useState('');
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);

  const [activePartner, setActivePartner] = useState(pushRequest.data.partner_id_partner);
  const [activeProblem, setActiveProblem] = useState(pushRequest.data.problem_id_problem);
  const [cursorPosition, setCursorPosition] = useState( pushRequest.data.text.length);

  const [problems, setProblems] = useState([
    { key: -1},
  ]);

  const [loading, setLoading] = useState(true);

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);

          const payload = {
            text: values.text,
            time_start: moment(values.timeStart, 'HH:mm').format('HH:mm'),
            time_stop: moment(values.timeStop, 'HH:mm').format('HH:mm'),
            additional_pull_request: values.parentAdditionalPullRequest ? values.parentAdditionalPullRequest : [],
            problem_id_problem: activeProblem,
            partner_id_partner: activePartner,
            problem_behaviors: values.problemBehaviors,
            is_active: values.isActive
          };

          editPushRequest(pushRequest.data.id_push_request, payload)
            .then((res) => {
              setLoading(false);
              message.success('Данные успешно изменены');
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, activeProblem, activePartner, loading]
  )

  const { getFieldDecorator, setFieldsValue } = form

  useEffect(() => {

    fetchProblemAll().then((res: any) => {
      const {data} = res.data;
      setProblems(data);
      setLoading(false);
    })

    fetchTags().then((res: any) => {
      const {data} = res.data;
      setTags(data);
      setLoadingTags(false);
    })

    fetchAllPullRequest().then((res: any) => {
      const {data} = res.data;
      setAllPullRequest(data);
      setLoadingAllPullRequest(false)
    })

  },[]);

  const changeText = useCallback((e, param = '') => {
    const {getFieldsValue, setFieldsValue} = form;
    let values = getFieldsValue();
    const tag = `<${e}${param}>`;

    const newText = `${values.text.slice(0,cursorPosition)}${tag}${values.text.slice(cursorPosition,values.text.length)}`;

    values = {...values, text: newText};
    setFieldsValue(values)
  },[cursorPosition]);

  const clickTag = useCallback((item) => {
    if (item.name === 'link') {
      setParamType(item.name)
      setVisibleModal(true)
    }else{
      changeText(item.name)
    }
  },[cursorPosition]);

  return (
    <>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Item label={strings.activity} className={styles.name}>
          {getFieldDecorator('isActive', { valuePropName: 'checked', initialValue: !!pushRequest.data.is_active })(<Switch />)} 
        </Form.Item>
        <Form.Item label={strings.periodSend} className={styles.name}>
          {`${strings.from} `}
          {getFieldDecorator('timeStart', { 
                initialValue: moment(pushRequest.data.time_start ? pushRequest.data.time_start : '00:00', 'HH:mm'),
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Укажите время',
                    whitespace: true,
                  },
                ],
           })(
            <TimePicker format='HH:mm' placeholder="Дата отправки" onChange={() => console.log(123)} />
          )} 
          {` ${strings.to} `} 
          {getFieldDecorator('timeStop', { 
                initialValue: moment(pushRequest.data.time_stop ? pushRequest.data.time_stop : '00:00', 'HH:mm'),
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: 'Укажите время',
                    whitespace: true,
                  },
                ],
           })(
            <TimePicker format='HH:mm' placeholder="Дата отправки" onChange={() => console.log(123)} />
          )} 
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={strings.partner} className={styles.name}>
            {getFieldDecorator("partner", {
                initialValue: pushRequest.data.partner_id_partner,
                rules: [
                  { required: true, message: strings.partner },
                ],
              })(
                <Select placeholder="Выберите партнера" onChange={(e: any) => {
                  setActiveProblem(null)
                  setFieldsValue({problem: null})
                  setActivePartner(e)}
                }>
                  {config.list_partner.map((item: any) => <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={strings.conditions} className={styles.name}>
              {getFieldDecorator("problem", {
                initialValue: activeProblem,
                rules: [
                  { required: true, message: strings.problem },
                ],
              })(
                <Select placeholder="Выберите проблему" onChange={(e: any) => setActiveProblem(e)}>
                  {problems.map((item: any) => (item.partner === activePartner || item.partner ===20) &&
                   item.data.map((itemProblem: any) => <Option key={itemProblem.id_problem} value={itemProblem.id_problem}>{itemProblem.problem_name}</Option>))}
                </Select>
              )}
              {getFieldDecorator("problemBehaviors", {
                initialValue: pushRequest.data.problem_behaviors,
                rules: [
                  { required: true, message: strings.condition },
                ],
              })(
                <Select placeholder="Выберите условие">
                  {config.list_type_problem_behaviors.map((item: any) => <Option key={item.id} value={item.id}>{item.title}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={strings.additionalAnswer} className={styles.name}>
          {!loadingAllPullRequest
            ? getFieldDecorator("parentAdditionalPullRequest", {
                initialValue: Array.from(pushRequest.data.child_additional_pull_request, (e: any) => e.id_pull_request),
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Выберите ответы"
                  onChange={() => console.log(123)}
                >
                  {
                    allPullRequest.map((item: any) => <Option disabled={item.id_pull_request === pushRequest.data.id_pull_request} value={item.id_pull_request} key={item.id_pull_request}>{item.button_text}</Option>)
                  }
                </Select>)
            : <Skeleton active />
          }
        </Form.Item>

        <Form.Item label={strings.textPush} >
          <Row gutter={16}>
            <Col span={12}>
              {getFieldDecorator("text", {
                initialValue: pushRequest.data.text,
                rules: [
                  { required: true, message: strings.textPush },
                ],
              })(
                <TextArea
                  onBlur={(e) => {
                    setCursorPosition(e.target.selectionStart)
                  }}
                  rows={4}
                  placeholder={strings.textPush}
                />
              )}
            </Col>
            <Col span={12}>
              {
                !loadingTags
                  ? tags.map((item: any) =>
                    <Tag key={item.id_tag} onClick={() => clickTag(item)}>
                      {item.title}
                    </Tag>)
                  : <Skeleton active />
              }
            </Col>
          </Row>
        </Form.Item>

        <Form.Item className={styles.name}>
          <Button type="primary" htmlType="submit" block size="large" loading={loading}>
            {strings.save}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Добавление параметра"
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            changeText(paramType, `#${paramValue}`);
            setParamValue('');
            setVisibleModal(false)
          }}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label="Значение">
          <Input value={paramValue} onChange={(e: any) => setParamValue(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  )
})

const SinglePushRequest = (props: any) => {

  const [pushRequest, setPushRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idPushRequest} = props;

    getPushRequest(idPushRequest, {})
      .then((res: any) => {
        setPushRequest(res.data);
        setLoading(false)
      } )
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });
  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <PushRequestForm {...props} pushRequest={pushRequest} />
  )
}

export default SinglePushRequest
