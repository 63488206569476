import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select} from "antd"

import Page from "shared/components/Page"
import SingleTag from "./screens/SingleTag";
import ListTag from "./screens/ListTag";
import {addTag, deleteTag} from "../../api/tags";
import useSelector from "../../shared/hooks/useSelector"
import { strings } from "utils/localization";

const { Option } = Select;

interface TagProps { history: any}

function Tag({...props}: TagProps): ReactElement {

  const config = useSelector(({ config }) => config)
  const defaultValue = {
    title: "",
    name: "",
    factor_id_factor: null,
    period_type_id_period_type: 1
  }
  const {pathname} = props.history.location;
  const idTag = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState(defaultValue);
  const [key, setKey] = useState(1);
  const [currentTag, setCurrentTag] = useState({data: {title: ''}});

  const addNewTag = useCallback(() => {

    setLoading(true);

    addTag(tag)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        setTag(defaultValue)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setTag(defaultValue)
        setLoading(false)
      })
  },[tag, key, visibleModal, loading]);

  const deleteCurrentTag = useCallback(() => {

    setLoading(true);

    deleteTag(idTag)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idTag]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idTag,
          title: idTag ? currentTag.data.title :strings.tags,
          actionButtons: !idTag ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addTag}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentTag()}>
              {strings.removeTag}
            </Button>,
          ],
        }}
      >
        {idTag
          ?
            <SingleTag {...props} idTag={idTag} setCurrentTag={setCurrentTag} />
          :
            <ListTag key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedTag}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!tag.name || !tag.title || !tag.factor_id_factor || !tag.period_type_id_period_type)} onClick={() => addNewTag()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.tagName}>
          <Input value={tag.title} onChange={(e: any) => setTag({...tag, title: e.target.value})} />
        </Form.Item>
        <Form.Item label={strings.identity}>
          <Input value={tag.name} onChange={(e: any) => setTag({...tag, name: e.target.value})} />
        </Form.Item>
        <Form.Item label={strings.factor} >
          <Select style={{width: '100%'}} placeholder={strings.changeFactor} value={tag.factor_id_factor} onChange={(e: any) => {
            setTag({...tag, factor_id_factor: e})}
          }>
            {config.list_factor.map((item: any) => <Option key={item.id_factor} value={item.id_factor}>{item.factor_name}</Option>)}
          </Select>
        </Form.Item>
        {/* <Form.Item label="Период" >
          <Select style={{width: '100%'}} placeholder="Выберите период" value={tag.period_type_id_period_type} onChange={(e: any) => {
            setTag({...tag, period_type_id_period_type: e})}
          }>
            {config.list_period.map((item: any) => <Option key={item.id_period_type} value={item.id_period_type}>{item.period_name}</Option>)}
          </Select>
        </Form.Item> */}
      </Modal>
    </>
  )
}

export default Tag
