import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select, InputNumber} from "antd"

import Page from "shared/components/Page"
import SingleRecommendation from "./screens/SingleRecommendation";
import useSelector from "../../shared/hooks/useSelector"
import ListRecommendation from "./screens/ListRecommendation";
import {addRecommendation, deleteRecommendation} from "../../api/recommendation";
import { fetchProblemAll } from "api/problem";
import _ from "lodash";
import { strings } from "utils/localization";

const { Option, OptGroup } = Select;

interface RecommendatonProps { history: any}

function Recommendaton({...props}: RecommendatonProps): ReactElement {

  const defaultState = {
    problem: "",
    text: "",
    dayCount: 0,
    weight: 0
  }
  const config = useSelector(({ config }) => config)
  const {pathname} = props.history.location;
  const idRecommendation = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recommendation, setRecommendation] = useState(defaultState);
  const [key, setKey] = useState(1);
  const [currentRecommendation, setCurrentRecommendation] = useState({data: {id_recommendation_problem: ''}});
  const [problems, setProblems] = useState([]);
  const [loadingProblems, setLoadingProblems] = useState(false);

  useEffect(() => {
    fetchProblemAll().then((res: any) => {
      const {data} = res.data;
      setProblems(data);
      setLoadingProblems(false);
    })
  },[])

  const addNewContext = useCallback(() => {

    setRecommendation(defaultState)

    setLoading(true);
    const payload = {
      problem_id_problem: recommendation.problem,
      text: recommendation.text,
      day_count: recommendation.dayCount,
      weight: recommendation.weight
    };

    addRecommendation(payload)
      .then((res: any) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        props.history.push({pathname: `/recommendation/${res.data.data.id_recommendation_problem}`})
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[recommendation, key, visibleModal, loading]);

  const deleteCurrentContext = useCallback(() => {

    setLoading(true);

    deleteRecommendation(idRecommendation)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idRecommendation]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idRecommendation,
          title: idRecommendation ? `${strings.recommendation} №${currentRecommendation.data.id_recommendation_problem}` :strings.recommendations,
          actionButtons: !idRecommendation ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addRecommendation}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentContext()}>
              {strings.removeRecommendation}
            </Button>,
          ],
        }}
      >
        {idRecommendation
          ?
            <SingleRecommendation {...props} idRecommendation={idRecommendation} setCurrentRecommendation={setCurrentRecommendation} problems={problems} config={config}/>
          :
            <ListRecommendation key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedRecommendation}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!recommendation.problem || !recommendation.text)} onClick={() => addNewContext()}>
            {strings.add}
          </Button>
        ]}
      >
        
        <Form.Item label={strings.problem}>
          <Select style={{width: '100%'}} loading={loadingProblems} value={recommendation.problem} onChange={(e: any) => setRecommendation({...recommendation, problem: e})}>
            {problems.map((item: any) =>
             <OptGroup key={item.partner} label={_.find(config.list_partner, {id_partner: item.partner}).name}>
              {item.data.map((itemProblem: any) => 
                <Option key={itemProblem.id_problem} value={itemProblem.id_problem}>{itemProblem.problem_name}</Option>
              )}
              </OptGroup>
            )}
          </Select>
        </Form.Item>
        <Form.Item label={strings.horizonResult}>
          <InputNumber style={{width: '100%'}} min={0} value={recommendation.dayCount} onChange={(e: any) => setRecommendation({...recommendation, dayCount: e})} />
        </Form.Item>
        <Form.Item label={strings.weight}>
          <InputNumber style={{width: '100%'}} min={0} max={100} value={recommendation.weight} onChange={(e: any) => setRecommendation({...recommendation, weight: e})} />
        </Form.Item>
        <Form.Item label={strings.textRecommendation}>
          <Input value={recommendation.text} onChange={(e: any) => setRecommendation({...recommendation, text: e.target.value})} />
        </Form.Item>
      </Modal>
    </>
  )
}

export default Recommendaton
