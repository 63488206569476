import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select, TimePicker} from "antd"

import Page from "shared/components/Page"
import SinglePushTemplate from "./screens/SinglePushTemplate";
import ListPushTemplate from "./screens/ListPushTemplate";
import {addPushTemplate, deletePushTemplate} from "../../api/pushTemplate";
import {useDispatch} from "react-redux";
import useSelector from "../../shared/hooks/useSelector"

import moment from "moment";
import "moment/locale/ru";
import { strings } from "utils/localization";

const { Option } = Select;

interface PushTemplateProps { history: any}

function PushTemplate({...props}: PushTemplateProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)

  const startData = {
    time_start: '00:00',
    time_stop: '00:00',
    partner_id_partner: null,
    context_id_context: null
  };

  const {pathname} = props.history.location;
  const idPushTemplate = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pushTemplate, setPushTemplate] = useState(startData);
  const [key, setKey] = useState(1);
  const [currentPushTemplate, setCurrentPushTemplate] = useState({data: {push_template_name: strings.template}});

  const addNewPushTemplate = useCallback(() => {

    setPushTemplate(startData)

    setLoading(true);
    const payload = pushTemplate;

    addPushTemplate(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[pushTemplate, key, visibleModal, loading]);

  const deleteCurrentPushTemplate = useCallback(() => {

    setLoading(true);

    deletePushTemplate(idPushTemplate)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idPushTemplate]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idPushTemplate,
          title: idPushTemplate ? `${strings.template} №${idPushTemplate}` :strings.pushExamples,
          actionButtons: !idPushTemplate ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addTemplate}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentPushTemplate()}>
              {strings.removeTemplate}
            </Button>,
          ],
        }}
      >
        {idPushTemplate
          ?
            <SinglePushTemplate {...props} idPushTemplate={idPushTemplate} setCurrentPushTemplate={setCurrentPushTemplate} />
          :
            <ListPushTemplate key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedTemplate}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!pushTemplate.time_start || !pushTemplate.time_stop || !pushTemplate.partner_id_partner || !pushTemplate.context_id_context)} onClick={() => addNewPushTemplate()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.periodSend} style={{width: '100%'}}>
          {`${strings.from} `}
          <TimePicker format='HH:mm' placeholder="Дата отправки" value={moment(pushTemplate.time_start ? pushTemplate.time_start : '00:00', 'HH:mm') } 
            onChange={(_: any, e:any) => setPushTemplate({...pushTemplate, time_start: moment(_).format('HH:mm')})} />
          {` ${strings.to} `}
          <TimePicker format='HH:mm' placeholder="Дата отправки" value={moment(pushTemplate.time_stop ? pushTemplate.time_stop : '00:00', 'HH:mm') } 
            onChange={(_: any, e:any) => setPushTemplate({...pushTemplate, time_stop: moment(_).format('HH:mm')})} />
        </Form.Item>
        <Form.Item label={strings.partner} >
          <Select style={{width: '100%'}} placeholder="Выберите партнера" value={pushTemplate.partner_id_partner} onChange={(e: any) => {
            setPushTemplate({...pushTemplate, partner_id_partner: e})}
          }>
            {config.list_partner.map((item: any) => <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item label={strings.context} >
          <Select style={{width: '100%'}} placeholder="Выберите контекст" value={pushTemplate.context_id_context} onChange={(e: any) => {
            setPushTemplate({...pushTemplate, context_id_context: e})}
          }>
            {config.list_context.map((item: any) => <Option key={item.id_context} value={item.id_context}>{item.context_name}</Option>)}
          </Select>
        </Form.Item>

      </Modal>
    </>
  )
}

export default PushTemplate
