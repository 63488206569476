import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select} from "antd"

import Page from "shared/components/Page"
import SinglePullRequest from "./screens/SinglePullRequest";
import ListPullRequest from "./screens/ListPullRequest";
import {addProblem, deleteProblem} from "../../api/problem";

import {configAction} from "../../redux/config/actions";
import {useDispatch} from "react-redux";
import useSelector from "../../shared/hooks/useSelector"
import {addPullRequest, deletePullRequest} from "../../api/pullRequest";
import { strings } from "utils/localization";

const { Option } = Select;

interface RequestProps { history: any}

function PullRequest({...props}: RequestProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {pathname} = props.history.location;
  const idPullRequest = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pullRequestName, setPullRequestName] = useState('');
  const [pullRequestText, setPullRequestText] = useState('');
  const [pullRequestSynonyms, setPullRequestSynonyms] = useState('');
  const [pullRequestContext, setPullRequestContext] = useState(null);
  const [key, setKey] = useState(1);
  const [currentPullRequest, setCurrentPullRequest] = useState({data: {button_text: ''}});

  const addNewPullRequest = useCallback(() => {

    setPullRequestName('');
    setPullRequestText('');
    setPullRequestSynonyms('');
    setPullRequestContext(null)
    setLoading(true);
    const payload = {
      button_text: pullRequestName,
      text: pullRequestText,
      synonyms: pullRequestSynonyms,
      context_id_context: pullRequestContext
    };

    addPullRequest(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[pullRequestName, pullRequestText, pullRequestContext, pullRequestSynonyms, key, visibleModal, loading]);

  const deleteCurrentPullRequest = useCallback(() => {

    setLoading(true);

    deletePullRequest(idPullRequest)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idPullRequest]);

  useEffect(() => {
    //console.log(problem.data)
    dispatch(configAction());
  }, []);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idPullRequest,
          title: idPullRequest ? currentPullRequest.data.button_text :strings.answerChatbot,
          actionButtons: !idPullRequest ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addAnswer}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentPullRequest()}>
              {strings.removeAnswer}
            </Button>,
          ],
        }}
      >
        {idPullRequest
          ?
            <SinglePullRequest {...props} idPullRequest={idPullRequest} setCurrentPullRequest={setCurrentPullRequest} />
          :
            <ListPullRequest key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.addedAnswer}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!pullRequestName || !pullRequestText || !pullRequestSynonyms || !pullRequestContext)} onClick={() => addNewPullRequest()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.buttonName}>
          <Input value={pullRequestName} onChange={(e: any) => setPullRequestName(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.context}>
          <Select value={pullRequestContext} onChange={(e: any) => setPullRequestContext(e)} style={{width: '100%'}}>
            {
              config.list_context.map((item: any) =>
                <Option key={item.id_context} value={item.id_context}>{item.context_name}</Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item label={strings.textAnswer}>
          <Input value={pullRequestText} onChange={(e: any) => setPullRequestText(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.synonyms}>
          <Input value={pullRequestSynonyms} onChange={(e: any) => setPullRequestSynonyms(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  )
}

export default PullRequest
