import React, { useEffect, useMemo, useState } from "react";

import "emoji-mart/css/emoji-mart.css";
import { Picker, Emoji } from "emoji-mart";
import { Col, Icon, Popover, Tooltip } from "antd";
import { strings } from "utils/localization";

const EmojiBlock = (props: any) => {

  const {form} = props
  const {getFieldValue, setFieldsValue} = form
  const [visible, setVisible] = useState(false);

  const emoji = useMemo(
    () => (
      <Picker
        set="google"
        useButton={false}
        native={true}
        recent={['smileys']}
        //include={['smileys']}
        exclude={['flags','foods','custom','activity','nature','objects','places','recent','symbols']}
        onSelect={(e: any) => setFieldsValue({name: `${getFieldValue('name')} ${e.native}`})}
        showSkinTones={false}
        showPreview={false}
        color="#2F80ED"
        i18n={{
          search: "Поиск",
          //clear: "Очистить", // Accessible label on "clear" button
          notfound: "Emoji не найдены",
          //skintext: "Выберите цвет кожи",
          categories: {
            search: "Результат поиска",
            recent: "Недавно используемые",
            people: "Эмоции и люди",
            nature: "Животные и природа",
            foods: "Еда и напитки",
            activity: "Спорт",
            places: "Туры и места",
            objects: "Объекты",
            symbols: "Символы",
            flags: "Флаги",
            custom: "Другое"
          },
          //categorieslabel: "Категории", // Accessible title for the list of categories
        }}
      />
    ),
    []
  );

  useEffect(() => {
    //setFieldsValue({name: "sfg"})
    //console.log(getFieldValue('name'))
  },[])

  return (
    <Tooltip title={strings.addEmoji}>
      <Popover 
        content={emoji} 
        trigger="click" 
        visible={visible}
        onVisibleChange={(e) => setVisible(e)}
      >
        <Icon type="smile" theme="twoTone" />
      </Popover>
    </Tooltip>
  );
};

export default EmojiBlock;
