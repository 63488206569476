import client from "./client"


export function fetchPullRequest(): Promise<any>{
  return client.get("pull-request")
}

export function getPullRequest(id: string, payload: object): Promise<null>{
  return client.get(`pull-request/${id}`)
}

export function addPullRequest(payload: object): Promise<null> {
  return client.post("/pull-request", payload)
}

export function editPullRequest(id: string, payload: object): Promise<null> {
  return client.put(`/pull-request/${id}`, payload)
}

export function deletePullRequest(id: string): Promise<null> {
  return client.delete(`/pull-request/${id}`)
}

export function fetchAllPullRequest(): Promise<any>{
  return client.get("pull-request-all")
}

export function fetchTags(): Promise<any>{
  return client.get("pull-request-tags")
}