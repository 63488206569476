import { Dispatch } from "redux"

import { IConfigAction, IConfigState, CONFIG_SUCCESS, CONFIG, CONFIG_FAILURE } from "./types"
import {  AxiosResponse } from "axios"
import {config} from "../../api/config";

type ConfigResponse = {
  list_factor: any
  list_condition: any
  list_period_link: any
  list_period: any
  list_partner: any
  list_unit: any
  list_button_type: any
  list_type_problem_behaviors: any
  list_cluster: any
  list_context: any
  list_support_status_type: any
  list_factor_type: any
}

type LoginError = {
  message: string
}

export const configAction = () => (dispatch: Dispatch) => {
  dispatch({ type: CONFIG })
  config()
    .then((res: AxiosResponse<ConfigResponse>) => {

      const { list_factor, list_condition, list_period_link, list_period, list_partner, list_unit, list_button_type, list_type_problem_behaviors, list_cluster, list_context, list_support_status_type, list_factor_type } = res.data;

      dispatch(configSuccessAction({ list_factor, list_condition, list_period_link, list_period, list_partner, list_unit, list_button_type, list_type_problem_behaviors, list_cluster, list_context, list_support_status_type, list_factor_type }))
    })
    .catch(() => dispatch<IConfigAction>({ type: CONFIG_FAILURE }))
}

const configSuccessAction = (payload: IConfigState): IConfigAction => ({
  type: CONFIG_SUCCESS,
  payload,
})

