import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tooltip, Table, Badge, Row} from "antd"

import { fetchPushRequest, fetchDetailHistoryProblem} from "../../../api/pushRequest";

import {configAction} from "../../../redux/config/actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import _ from "lodash";
import { strings } from "utils/localization";

interface HistoryProps { history: any, config: any}

function HistoryPushRequestProblem({...props}: HistoryProps): ReactElement {

    const dispatch = useDispatch()
    const {config} = props
  
    const columns = [
      {
        title: strings.condition,
        dataIndex: 'problem_id_problem',
        render: (render: number, row: any) => 
          <>
            <span>
              {strings.problem}
            </span>
            <Button style={{padding: 4}} type="link" onClick={() => gotoProblem(render)}>
              {row.problem.problem_name}
            </Button>
            <span style={{textTransform: 'lowercase'}}>
              {_.find(config.list_type_problem_behaviors, {id: row.problem_behaviors}).title}
            </span>
          </>
      },
      {
        title: strings.periodSend,
        dataIndex: 'time_start',
        render: (render: any, row: any) => `${strings.from} ${moment(row.time_start, "HH:mm").format("HH:mm")} ${strings.to} ${moment(row.time_stop, "HH:mm").format("HH:mm")}`
      },
      {
        title: strings.partner,
        dataIndex: 'partner_id_partner',
        render: (render: number) => _.find(props.config.list_partner, {id_partner: render}).name
      },
      {
        title: strings.message,
        dataIndex: 'text',
        render: (render: any, row: any) => `${render} ${row.score ? `Оценка: ${row.score}` : ``}`
      }
    ];
  
    const columnsDetails = [
      {
        dataIndex: 'status',
        render: (render: number) => 
          render === 1 ? 
            <Tooltip title='В процессе отправки'>
              <Badge status="processing" /> 
            </Tooltip>
          :  
            render === 2 ? 
              <Tooltip title='Отправлен'>
                <Badge status="success" /> 
              </Tooltip>
            :
              <Tooltip title='Ошибка'>
                <Badge status="error" />
              </Tooltip>
      },
      { title: strings.chatId, dataIndex: 'chat_id'},
      {
        title: strings.dateCreate,
        dataIndex: 'created_at',
        render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
      },
      {
        title: strings.dateChange,
        dataIndex: 'updated_at',
        render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
      },
    ];
  
    const [activeIdPushRequestCustom, setActiveIdPushRequestCustom] = useState(0);
    
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [pagination, setPagination] = useState({
      total: 10,
      page: 1
    });
  
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [detail, setDetail] = useState([]);
  
    const gotoProblem = React.useCallback((idProblem) => {
      const {history} = props;
  
      history.push({pathname: `/problems/${idProblem}`});
    },[]);
  
    const lookDetail = useCallback(
      (val, e) => {
        setActiveIdPushRequestCustom(e.id_push_request);
        if (val) {
          setLoadingDetail(true) 
          fetchDetailHistoryProblem(e.id_push_request).then((res: any) => {
  
              const {data} = res.data;
  
              let newDetail:any = detail;
              newDetail[e.id_push_request] = data
  
              setDetail(newDetail);
              setLoadingDetail(false);
              
          }).catch(() => setLoadingDetail(false));
        }
      },
      [activeIdPushRequestCustom]
    );
  
    useEffect(() => {
      dispatch(configAction());
    }, []);
  
    useEffect(() => {
      setLoading(true);
  
      fetchPushRequest(pagination.page)
        .then((res: any) => {
          const {data} = res.data;
          const {total} = res.data
  
          setPagination({...pagination, total: total});
          setHistory(data);
          setLoading(false);
  
        })
        .catch(() => setLoading(false))
  
    },[pagination.page]);
  
    // @ts-ignore
    // @ts-ignore
    return (
        <Table
            loading={loading}
            size="small"
            onChange={(e:any) => setPagination({...pagination, page: e.current})}
            pagination={{
                total: pagination.total,
                current: pagination.page
            }}
            expandedRowRender={(record, key) => (
                <Row>
                <Table 
                    loading={loadingDetail}
                    bordered={false}
                    columns={columnsDetails} 
                    dataSource={detail[record.id_push_request]} 
                    rowKey={(record: any) => `${record.chat_id}_${record.created_at}`}
                    size="small"
                />
                </Row>
            )}
            onExpand={(e, record) => lookDetail(e, record)}
            rowKey={(record: any) => record.id_push_request}
            columns={columns}
            dataSource={history}
        />
    )
  }
  
  export default HistoryPushRequestProblem