import { addRecommendation } from 'api/recommendation';
import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en:{
        home:"Home",
        charts:"Charts",
        changeFactor: "Change factor",
        problems: "Problems",
        addProblem: "Add problem",
        removeProblem: "Remove problem",
        appendProblem: "Append problem",
        problemName: "Problem name",
        sellerName: "Seller name",
        name: "Name",
        parentProblem: "Parent problem",
        conditionProblemExist: "Condition problem exist",
        conditionProblemDisappear: "Condition problem disappear",
        triggerControl: "Trigger control",
        save: "Save",
        and: "And",
        or: "Or",
        availability: "availability",
        thereIs: "There is",
        absent: "Absent",
        addCondition: "Add condition",
        createGroup: "Create Group",
        not: "Not",
        selectTrigger: "Select trigger",
        trigger: "Trigger",
        delete: "Delete",
        factor: "Factor",
        behavior: "Вehavior",
        value: "Value",
        on: "on",
        unit: "Unit",
        count: "Count",
        periodType: "Period type",
        periodLink: "Period link",
        add: "Add",
        cancel: "Cancel",
        changeSeller: "Change seller",
        factors: "Factors",
        addFactor: "Add factor",
        removeFactor: "Remove factor",
        addedFactor: "Added factor",
        factorName: "Factor name",
        operand: "Operand",
        factorType: "Factor type",
        formula: "Formula",
        factsCount: "Facts count",
        type: "Type",
        execTime: "Exec time",
        problemHistory: "Problem history",
        description: "Description",
        sqlQuery: "SQL-query",
        sqlQueryAnnotation: "To correctly execute an SQL query, you must follow the following rules",
        sqlQueryMinParams: "There must be at least 3 output parameters (partner_id, value, count)",
        sqlQueryTemplates: "Start and end date templates available (!datebegin and !dateend respectively)",
        sqlQueryButton: "Request validation check button",
        dateBegin: "Date begin",
        dateEnd: "Date end",
        problem: "Problem",
        contexts: "Contexts",
        addContext: "Add context",
        removeContext: "Remove context",
        addedContext: "Added context",
        contextName: "Context name",
        pushHistory: "Push history",
        messages: "Messages",
        externalQuestionId: "External question ID",
        dateCreate: "Date create",
        periodSend: "Period send",
        clientFio: "Client FIO",
        clientPhone: "Client phone",
        partner: "Partner",
        message: "Message",
        condition: "Condition",
        dateChange: "Date change",
        chatId: "Chat ID",
        recommendations: "Recommendations",
        recommendation: "Recommendation",
        pushExamples: "Push examples",
        context: "Context",
        addTemplate: "Add template",
        addedTemplate: "Added template",
        removeTemplate: "Remove template",
        template: "Template",
        answerChatbot: "Chatbot answer",
        addAnswer: "Add answer",
        addedAnswer: "Added answer",
        removeAnswer: "Remove answer",
        buttonName: "Button name",
        buttonType: "Button type",
        parentAnswer: "Parent answer",
        hasRedirect: "Has redirect",
        redirectAnswer: "Redirect answer",
        synonyms: "Synonyms",
        synonymsDescr: "Synonyms are listed separated by commas",
        textAnswer: "Text answer",
        addParametr: "Parametr add",
        pushChatbot: "Chatbot push",
        textMessage: "Text message",
        addPush: "Add push",
        addedPush: "Added push",
        textPush: "Text push",
        removePush: "Remove push",
        activity: "Activity",
        conditions: "Conditions",
        additionalAnswer: "Additional answer",
        push: "Push",
        addRecommendation: "Add recommendation",
        addedRecommendation: "Added recommendation",
        removeRecommendation: "Remove recommendation",
        horizonResult: "Result horizon in days",
        weight: "Weight",
        textRecommendation: "Recommendation text",
        impactOnFactors: "Impact on factors",
        tags: "Tags",
        addTag: "Add tag",
        addedTag: "Added tag",
        removeTag: "Remove tag",
        identity: "Identity",
        tagName: "Tag name",
        support: "Support",
        chat: "Chat",
        collaboration: "Collaboration",
        answer: "Answer",
        textQuestion: "Text question",
        status: "Status",
        clientQuestion: "Client question",
        comment: "Comment",
        from: "From",
        to: "to",
        addEmoji: "Add Emoji"
    },
    ru: {
        home:"Главная",
        charts: "Графики",
        changeFactor: "Выберите фактор",
        problems: "Проблемы",
        addProblem: "Добавить проблему",
        removeProblem: "Удалить проблему",
        appendProblem: "Добавление проблемы",
        problemName: "Название проблемы",
        sellerName: "Торговая сеть",
        name: "Название",
        parentProblem: "Родительская проблема",
        conditionProblemExist: "Условия появления проблемы",
        conditionProblemDisappear: "Условия снятия проблемы",
        triggerControl: "Управление триггерами",
        save: "Сохранить",
        and: "И",
        or: "Или",
        exist: "Существует",
        availability: "наличие",
        thereIs: "Имеется",
        absent: "Отсутствует",
        addCondition: "Добавить условие",
        createGroup: "Создать группу",
        not: "Не",
        selectTrigger: "Выберите триггер",
        trigger: "Триггер",
        delete: "Удалить",
        factor: "Фактор",
        behavior: "Поведение",
        value: "Значение",
        on: "на",
        unit: "Ед. измерения",
        count: "Кол-во",
        periodType: "Тип периода",
        periodLink: "Связь периода",
        add: "Добавить",
        cancel: "Отмена",
        changeSeller: "Выберите торговую сеть",
        factors: "Факторы",
        addFactor: "Добавить фактор",
        removeFactor: "Удалить фактор",
        addedFactor: "Добавление фактора",
        factorName: "Название фактора",
        operand: "Операнд",
        factorType: "Тип фактора",
        formula: "Формула",
        factsCount: "Кол-во фактов",
        type: "Тип",
        execTime: "Время выполнения",
        problemHistory: "История проблем",
        description: "Описание",
        sqlQuery: "SQL-запрос",
        sqlQueryAnnotation: "Для корректного выполнения SQL-запроса необходимо следовать следующим правилам",
        sqlQueryMinParams: "Выходных параметров должно быть минимум 3 (partner_id, value, count)",
        sqlQueryTemplates: "Доступны шаблоны указания даты начала и конца (!datebegin и !dateend соответственно)",
        sqlQueryButton: "Кнопка проверки валидации запроса",
        dateBegin: "Дата начала",
        dateEnd: "Дата окончания",
        problem: "Проблема",
        contexts: "Контексты",
        addContext: "Добавить контекст",
        removeContext: "Удалить контекст",
        addedContext: "Добавление контекст",
        contextName: "Название контекста",
        pushHistory: "История пушей",
        messages: "Сообщения",
        externalQuestionId: "ID внешнего вопроса",
        dateCreate: "Дата создания",
        periodSend: "Период отправки",
        clientFio: "Ф.И.О. адресата",
        clientPhone: "Телефон адресата",
        partner: "Партнер",
        message: "Сообщение",
        condition: "Условие",
        dateChange: "Дата изменения",
        chatId: "ID чата",
        recommendations: "Рекомендации",
        recommendation: "Рекомендация",
        pushExamples: "Шаблоны пушей",
        context: "Контекст",
        addTemplate: "Добавить шаблон",
        addedTemplate: "Добавление шаблона",
        removeTemplate: "Удалить шаблон",
        template: "Шаблон",
        answerChatbot: "Отчеты для чат-бота",
        addAnswer: "Добавить ответ",
        addedAnswer: "Добавление ответа",
        removeAnswer: "Удалить ответ",
        buttonName: "Название кнопки",
        buttonType: "Тип кнопки",
        parentAnswer: "Родительский ответ",
        hasRedirect: "Имеет перенаправление",
        redirectAnswer: "Перенаправление на ответ",
        synonyms: "Синонимы",
        synonymsDescr: "Перечисление синонимов осуществляется через запятую",
        textAnswer: "Текст ответа",
        addParametr: "Добавление параметра",
        pushChatbot: "Пуши для чат-бота",
        textMessage: "Текст сообщения",
        addPush: "Добавить пуш",
        addedPush: "Добавление пуша",
        textPush: "Текст пуша",
        removePush: "Удалить пуш",
        activity: "Активность",
        conditions: "Условия",
        additionalAnswer: "Дополнительные ответы",
        push: "Пуш",
        addRecommendation: "Добавить рекомендацию",
        addedRecommendation: "Добавление рекомендации",
        removeRecommendation: "Удалить рекомендацию",
        horizonResult: "Горизонт результата в днях",
        weight: "Вес",
        textRecommendation: "Текст рекомендации",
        impactOnFactors: "Влияние на факторы",
        tags: "Теги",
        addTag: "Добавить тег",
        addedTag: "Добавление тега",
        removeTag: "Удалить тег",
        identity: "Идентификатор",
        tagName: "Название тега",
        support: "Поддержка",
        chat: "Чат",
        collaboration: "Совместная работа",
        answer: "Ответ",
        textQuestion: "Текст вопроса",
        status: "Статус",
        clientQuestion: "Вопрос клиента",
        comment: "Комментарий",
        from: "С",
        to: "до",
        addEmoji: "Добавить Emoji"
    }
});