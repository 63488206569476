import React, { PureComponent, ReactElement, useEffect, useState, useCallback } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Brush, Surface, Symbols
} from 'recharts';
import regression from 'regression';

import { DateTime } from "luxon";

import {Button, Tooltip as ToolAnt, Row, Select, Form, Col, DatePicker } from "antd"
import {getFactByFactor} from "../../../../api/fact";
import moment from "moment";
import _ from "lodash";
import ExportExcel from 'shared/components/Excel';
import { strings } from 'utils/localization';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface GraphProps { config: any }

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

function RechartGraph({...props}: GraphProps): ReactElement {

  const {config} = props;
  const [loading, setLoading] = useState(true);
  const [factor, setFactor] = useState('');
  const [partners, setPartners] = useState([]);
  const [chartData, setChartData] : any = useState([]);
  const [activeLine, setActiveLine] : any = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(true);
  const [excelData, setExcelData] = useState(null);
  const [keyExcel, setKeyExcel] = useState(1);
  const [maxValue, setMaxValue] = useState('null');
  const [params, setParams] = useState({
    date1: DateTime.local()
      .minus({ months: 2 })
      .toFormat("dd.MM.yyyy"),
    date2: DateTime.local().toFormat("dd.MM.yyyy")
  });
  const [showTrend, setShowTrend] = useState(false);
  const [trendName, setTrendName] = useState("Линия тренда");

  useEffect(() => {
    setLoading(true);
    factor &&
    getFactByFactor(factor, params).then((res: any) => {
      const {data} = res.data;

      setChartData(data.dataChart);
      setPartners(data.partners);
      setLoading(false)
    })
  },[factor, params])

  useEffect(() => {
    const b : any = _.maxBy(chartData, activeLine)
    b && setMaxValue(b[activeLine])
    //b && setShowTrend(!!b[activeLine]) 
  },[activeLine])

  const formatXAxis = (tickItem: any) => {
    // If using moment.js
    return moment(tickItem).format('DD.MM.YYYY')
  }

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    const newActive = activeLine === dataKey ? null : dataKey;
    setActiveLine(newActive);

    if (showTrend) { 

      let regress: any = []
      let newChartData = []

      partners.map((item: any, key: number) => {
        if (item.id_partner === dataKey) {
          chartData.map((item: any, key: number) => regress[key] = [key+1, chartData[key][dataKey]])
        }
      })
      
      const resultRegress = regression.linear(regress);
      const trendLine: any = {
        start: resultRegress.equation[0] * 1 + resultRegress.equation[1],
        stop: resultRegress.equation[0] * regress.length + resultRegress.equation[1]
      }

      newChartData = chartData
      newChartData[0] = {...chartData[0], trend: parseInt(trendLine.start)}
      newChartData[chartData.length -1] = {...chartData[chartData.length -1], trend: parseInt(trendLine.stop)}

      //console.log(newChartData)
      setChartData(newChartData)
      setTrendName(`Линия тренда (${resultRegress.string})`)
    }
  }

  const getExcelData = useCallback(() => {

    setLoadingExcel(false);

    let newPartners :any = [];
    let newArr :any = [];

    partners.map(
      (item: any, key: number) =>
        newPartners[key] = { title: item.name }
    );

    let excelArr = [{
      columns: [{title: "Дата"},...newPartners],
      data: []
    }] as any;

    chartData.map(
      (item: any, key: number) => {
        
        let dataArray:any = []

        partners.map(
          (itemP: any, keyP: number) =>
            dataArray[keyP] = { value: item[itemP.id_partner] ? item[itemP.id_partner] : 0 }
        ); 

        newArr[key] = [
          { value: item.date },
          ...dataArray
        ]
      }
    );

    excelArr[0].data = newArr;

    setExcelData(excelArr);
    setLoadingExcel(true);
    setKeyExcel(Math.random())
  }, [partners, chartData, loadingExcel, excelData, keyExcel]);

  const renderCusomizedLegend = (props: any) => {
    const { payload } = props
    return (
      <div className="customized-legend" style={{textAlign: 'center'}}>
        {
          payload.map((entry: any) => {
            const { dataKey, color, value } = entry
            let style = {}
            if (dataKey === activeLine) {
              style = { fontWeight: 700 }
            }
            
            return (
                <span key={dataKey} className="legend-item" onClick={() => handleMouseEnter(entry)} style={{...style, marginRight: 10, whiteSpace: 'nowrap'}}>
                  <Surface width={20} height={10}>
                    <Symbols cx={10} cy={5} type="circle" size={50} fill={color} />
                  </Surface>
                  <span>{value}</span>
                </span>
          
            )
          })
        }
      </div>
    )
  }

  const CustomTooltip = ({ active, payload, label } : any) => {
    if (active) {

      return (
        <div style={{maxHeight: 400, overflow: 'auto', backgroundColor: 'rgb(255 255 255 / 0.80)', padding: 10, borderRadius: 4, border: '1px solid rgb(204, 204, 204)'}}>
          <p style={{fontWeight: 700}}>{moment(label).format('DD.MM.YYYY')}</p>
          {_.orderBy(payload,['value'],['desc']).map((item: any, key: number) => !!activeLine
            ? (activeLine === item.dataKey || item.dataKey==='trend') && <p style={{color: item.stroke}} key={item.dataKey}>{item.name} : {item.value}</p>
            : <p style={{color: item.stroke, fontSize: 12, lineHeight: '20px', marginBottom: 0}} key={item.dataKey} >{item.name} : {item.value}</p>
          )}
        </div>
      );
    }
  
    return null;
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <Form.Item label={strings.charts} >
      <Row type="flex" justify="start" gutter={8}>
        <Col>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={strings.changeFactor}
            optionFilterProp="children"
            onChange={(e: any) => {
              setMaxValue('null')
              setActiveLine(0)
              setFactor(e)
              setShowTrend(true)
            }}
            filterOption={(input: any, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              config.list_factor.map((item: any) => <Option key={item.id_factor} value={item.id_factor}>{item.factor_name}</Option>)
            }
          </Select>
        </Col>
        <Col>
          <RangePicker  
            onChange={(_, e) => {
              setParams({ ...params, date1: e[0], date2: e[1] });
            }} 
            defaultValue={[
              moment(params.date1, "DD.MM.YYYY"),
              moment(params.date2, "DD.MM.YYYY")
            ]}
            format="DD.MM.YYYY" 
          />
        </Col>
        <Col>
          {!loading &&
          <ToolAnt title="Выгрузить в Excel">
            <Button  
              //loading={loadingExcel}
              type="link"
              icon="file-excel"
              onClick={() => getExcelData()}
            />
          </ToolAnt>
          }
        </Col>
      </Row>
      {!loading &&
        <div style={{ width: '95%', height: 600 }}>
          <ResponsiveContainer>
            <LineChart
              data={chartData}
              margin={{
                top: 50, right: 30, left: 30, bottom: 25,
              }}
            >
              <Brush dataKey="date" tickFormatter={formatXAxis} height={20} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis scale="auto" dataKey="date" tickFormatter={formatXAxis} />
              <YAxis allowDataOverflow={true} domain={[0, maxValue]}/>
              <Tooltip wrapperStyle={{pointerEvents: 'auto'}} content={<CustomTooltip />} />
              <Legend onClick={handleMouseEnter} content={renderCusomizedLegend} />
              {showTrend && <Line connectNulls type="monotone" dataKey="trend" name={trendName} stroke="#8884d8" strokeDasharray="5 5" />}
              {partners.map((item: any, key: number) => <Line opacity={(item.id_partner === activeLine || !activeLine) ? 1 : 0.1} key={item.id_partner} type="monotone" dataKey={item.id_partner} name={item.name} strokeOpacity={1} stroke={COLORS[key]} />)}
            </LineChart>
          </ResponsiveContainer>
        </div>
      }

      {keyExcel > 0 && excelData &&
        <ExportExcel key={keyExcel} data={excelData} title={_.find(config.list_factor, {id_factor: factor}).factor_name} />
      }
    </Form.Item>
  )
}

export default RechartGraph
