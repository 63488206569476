import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Table, Tooltip, Button} from "antd"
import _ from "lodash";
import {fetchFactor} from "../../../../api/factor";
import useSelector from "../../../../shared/hooks/useSelector"
import { strings } from "utils/localization";

interface FactorProps { history: any}

function ListFactor({...props}: FactorProps): ReactElement {

  const config = useSelector(({ config }) => config)

  const columns = [
    {
      dataIndex: 'id_factor',
      render: (render: any) => (
        <Tooltip title="Редактирование">
          <Button
            type="link"
            onClick={e => history.push({pathname: `/factors/${render}`})}
            shape="circle"
            icon="edit"
          />
        </Tooltip>
      )
    },
    {
      title: strings.name,
      dataIndex: 'factor_name',
    },
    {
      title: strings.operand,
      dataIndex: 'var_name',
    },
    {
      title: strings.formula,
      dataIndex: 'formula',
    },
    {
      title: strings.factsCount,
      dataIndex: 'fact_count',
    },
    {
      title: strings.type,
      dataIndex: 'factor_type',
      render: (render: number) => _.find(config.list_factor_type, {id: render}).title
    },
    {
      title: strings.execTime,
      dataIndex: 'exec_time',
      render: (render: number, row: any) => row.query_count ? `${row.query_count} записей за ${render.toFixed(2)} сек.` : `-`
    }
  ];

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [factors, setFactors] = useState([]);

  useEffect(() => {
    fetchFactor().then((res) => {
      const {dataModels} = res.data.data;

      setFactors(dataModels);
      setLoading(false);
    })

  },[]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    <Table
      loading={loading}
      size="small"
      rowKey={(record: any) => record.id_factor}
      columns={columns}
      dataSource={factors}
      pagination={{ pageSize: 20}}
    />
  )

}

export default ListFactor
