import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Row, Col, Input, Spin, Form, message, Tag} from "antd"

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import {editContext, getContext} from "../../../../api/context";
import NotFound from "../../../NotFound";
import { strings } from "utils/localization";

const ContextForm = Form.create({ name: "context" })((props) => {

  const dispatch = useDispatch()
  const {form} : FormComponentProps = props;
  const {context} : any = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          const payload = {
            context_name: values.name,
            var_name: values.var_name
          };

          editContext(context.data.id_context, payload)
            .then((res) => {
              message.success('Данные успешно изменены');
              setLoading(false);
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, loading]
  )

  const { getFieldDecorator } = form

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.name} className={styles.name} >
            {getFieldDecorator("name", {
              initialValue: context.data.context_name,
              rules: [
                { required: true, message: strings.contextName },
              ],
            })(
              <Input
                placeholder={strings.name}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="start" gutter={32}>
        <Col>
          <Form.Item label={strings.operand} className={styles.name} >
            {getFieldDecorator("var_name", {
              initialValue: context.data.var_name,
              rules: [
                { required: true, message: strings.operand },
              ],
            })(
              <Input
                placeholder={strings.operand}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SingleContext = (props: any) => {

  const [context, setContext] = useState({});
  const [contexts, setContexts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idContext, setCurrentContext} = props;

    getContext(idContext, {})
      .then((res: any) => {
        setContext(res.data);
        setCurrentContext(res.data)
        setLoading(false)
      } )
      .catch(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });

  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <ContextForm {...props} context={context} />
  )
}

export default SingleContext
