import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select} from "antd"

import Page from "shared/components/Page"

import {configAction} from "../../redux/config/actions";
import {useDispatch} from "react-redux";
import useSelector from "../../shared/hooks/useSelector"
import RechartGraph from "./screens/Graphs/rechartGraph";
import {strings} from "../../utils/localization"

const { Option } = Select;

interface HomeProps { history: any}

function Home({...props}: HomeProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {pathname} = props.history.location;

  useEffect(() => {
    //console.log(problem.data)
    dispatch(configAction());
  }, []);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: false,
          title: strings.home,
          actionButtons: [],
        }}
      >
        <RechartGraph config={config} />
      </Page>
    </>
  )
}

export default Home
