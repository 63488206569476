import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Button, Tree, Skeleton, Typography } from "antd"

import Page from "shared/components/Page"
import {editProblemAction, fetchProblemAction} from "../../../../redux/problem/actions"
import {useDispatch} from "react-redux";
import {editProblem, fetchProblem, getProblem} from "../../../../api/problem";
import _ from "lodash";
import useSelector from "../../../../shared/hooks/useSelector"

interface ProblemProps { history: any, partnerProblems: any, partnerLoading: any, partner: any}

const { TreeNode } = Tree;
const { Title } = Typography;

function CustomTree({...props}: ProblemProps): ReactElement {

  const config = useSelector(({ config }) => config)

  const dispatch = useDispatch()

  const {history, partnerProblems, partnerLoading, partner} = props;
  const partnerName = _.find(config.list_partner, {id_partner: partner}).name;

  const [loading, setLoading] = useState(partnerLoading);
  const [problems, setProblems] = useState(partnerProblems);

  const updateProblem = useCallback( (info, parent) => {
    const {id_problem} = info.dragNode.props.dataRef;
    const payload = {
      problem_id_problem: parent
    };

    id_problem && dispatch(editProblemAction(id_problem, payload))

    // id_problem &&
    // editProblem(id_problem, payload).then((res: any) => {
    //   console.log(res)
    // }).catch((err: any) => {
    //   console.log(err)
    // });

    //console.log(id_problem, parentProblem);
  },[]);

  const onLoadData = useCallback((treeNode) =>
    getProblem(treeNode.props.id_problem, treeNode.props.title).then((res: any) => {

      const {data} = res.data;
      const newState = Object.assign({}, data.child_problems)

      data.child_problems.forEach(function (item: any, i: number) {
        newState[i] = {
          ...item,
          isLeaf: item.child_problems.length <= 0,
          title: item.problem_name,
          key: `${data.id_problem}-${item.id_problem}`
        }
      });

      treeNode.props.dataRef.children = Object.values(newState);
      setProblems([...problems]);

    }).catch((err) => console.log(err))
  ,[problems])

  const renderTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });

  const onDragEnter = (info: any) => {
    //console.log("onDragEnter", info);
    // expandedKeys 需要受控时设置
    // this.setState({
    //   expandedKeys: info.expandedKeys,
    // });
  };

  const onDrop = (info: any) => {

    //console.log("onDrop", info);
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data: any[], key: any, callback: (item: any, index: any, arr: any) => void) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };

    const data = [...problems];
    let parent = info.node.props.dataRef.id_problem;

    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
      if (!info.dropToGap) dragObj = {...item, problem_id_problem: info.node.props.dataRef.id_problem};
      //console.log(info.node.props.dataRef.id_problem)
      //dragObj = {...item, problem_id_problem: info.dragNode.props.dataRef.problem_id_problem};
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item: { children: any[], problem_id_problem: any }) => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.push(dragObj);
      });
      //console.log(2)
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      //console.log(2)
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      //console.log(3)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        ar[index] = {...ar[index], problem_id_problem: null};
        i = index;
      });
      if (dropPosition === -1) {
        // @ts-ignore
        ar.splice(i, 0, dragObj);
        parent = dropPos.length >= 3 ? info.node.props.dataRef.problem_id_problem : null;
      } else {
        // @ts-ignore
        ar.splice(i + 1, 0, dragObj);
        parent = dropPos.length >= 3 ? info.node.props.dataRef.problem_id_problem : null;
      }
    }

    updateProblem(info, parent);
    setTimeout( () => setProblems(data), 10)
  };

  // @ts-ignore
  // @ts-ignore
  return (
    loading
      ? <Skeleton active />
      :
      <>
        <Title level={4} style={{fontSize: 14}}>{partnerName}</Title>
        <Tree
          className="draggable-tree"
          loadData={onLoadData}
          //draggable={true}
          blockNode={false}
          showLine={true}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          onClick={(e, i) => history.push({pathname: `/problems/${i.props.dataRef.id_problem}`})}
        >
          {renderTreeNodes(problems)}
        </Tree>
      </>
  )
}

export default CustomTree
