import client from "./client"


export function fetchPushRequest(page: number): Promise<any>{
  return client.get(`push-request?page=${page}`)
}

export function getPushRequest(id: string, payload: object): Promise<null>{
  return client.get(`push-request/${id}`)
}

export function addPushRequest(payload: object): Promise<null> {
  return client.post("/push-request", payload)
}

export function editPushRequest(id: string, payload: object): Promise<null> {
  return client.put(`/push-request/${id}`, payload)
}

export function deletePushRequest(id: string): Promise<null> {
  return client.delete(`/push-request/${id}`)
}

export function fetchHistory(page: number): Promise<null> {
  return client.get(`push-request-custom?page=${page}`)
}

export function fetchDetailHistory(id: number): Promise<null> {
  return client.get(`push-request-custom/${id}/detail`)
}

export function fetchDetailHistoryProblem(id: number): Promise<null> {
  return client.get(`push-request/${id}/detail`)
}

export function fetchRecommendation(page: number): Promise<null> {
  return client.get(`push-request-recommendation?page=${page}`)
}

export function fetchDetailHistoryRecommendation(id: number): Promise<null> {
  return client.get(`push-request-recommendation/${id}/detail`)
}