import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tree, Input, Spin, Form, Select, Col, Skeleton, message} from "antd"

import {useDispatch} from "react-redux";
import {editProblem, fetchProblem, getProblem} from "../../../../api/problem";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";
import Triggers from "./Triggers";
import NotFound from "../../../NotFound";
import useSelector from "../../../../shared/hooks/useSelector"
import { strings } from "utils/localization";

interface ProblemProps { history: any, idProblem: any}

const { Option } = Select;
const { TreeNode } = Tree;

const ProblemForm = Form.create({ name: "problem" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {problem} : any = props;

  const [problems, setProblems] = useState([
    { key: -1},
  ]);
  const [activePartner, setActivePartner] = useState(problem.data.partner_id_partner);
  const [tree, setTree] = useState(problem.data.json_formula);
  const [formula, setFormula] = useState(problem.data.json_data);
  const [treeRemoval, setTreeRemoval] = useState(problem.data.json_formula_removal);
  const [formulaRemoval, setFormulaRemoval] = useState(problem.data.json_data_removal);

  const [loading, setLoading] = useState(true);
  const [problemIdProblem, setProblemIdProblem] = useState({
    checked: [],
    idParentProblem: problem.data.problem_id_problem
  });

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          const payload = {
            problem_name: values.name,
            problem_id_problem: problemIdProblem.idParentProblem,
            partner_id_partner: values.partner,
            json_data: formula,
            json_formula: tree,
            json_data_removal: formulaRemoval,
            json_formula_removal: treeRemoval
          };

          editProblem(problem.data.id_problem, payload)
            .then((res) => {
              setLoading(false);
              message.success('Данные успешно изменены');
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, problemIdProblem, loading, formula, tree, formulaRemoval, treeRemoval]
  )

  const { getFieldDecorator } = form

  const resetKey = useCallback((e, id) => {
      const arr: any = [e[e.length - 1]];
      setProblemIdProblem({
        checked: arr,
        idParentProblem: arr[0] ? id : null
      });
      // console.log({
      //   checked: arr,
      //   idParentProblem: arr[0] ? id : null
      // })
    },[problemIdProblem]);

  const onLoadData = useCallback((treeNode) =>
      getProblem(treeNode.props.id_problem, treeNode.props.title).then((res: any) => {

        const {data} = res.data;
        const newState = Object.assign({}, data.child_problems)

        const loop = (data: any[], key: any, callback: (item: any, index: any, arr: any) => void) => {
          data.forEach((item, index, arr) => {
            if (item.id_problem === key && item.disabled) {
              return callback(item, index, arr);
            }
            if (item.children) {
              return loop(item.children, key, callback);
            }
          });
        };

        //console.log(problems, problem.data.id_problem)

        data.child_problems.forEach(function (item: any, i: number) {

          let disable = false;

          loop(problems, data.id_problem, itemp => {
            itemp.children = itemp.children || [];
            itemp.disabled = true;
            disable = true;
          });

          newState[i] = {
            ...item,
            disabled: disable || problem.data.id_problem === item.id_problem,
            isLeaf: item.child_problems.length <= 0,
            title: item.problem_name,
            key: `${data.id_problem}-${item.id_problem}`
          };

          item.id_problem === problem.data.problem_id_problem && resetKey([`${data.id_problem}-${item.id_problem}`],item.id_problem)
        });

        treeNode.props.dataRef.children = Object.values(newState);
        setProblems([...problems]);

      }).catch((err) => console.log(err))
    ,[problems, problem]);

  const renderTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            disabled={item.id_problem === problem.data.id_problem || item.disabled}
            title={item.title} key={item.key} dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });

  useEffect(() => {

    setLoading(true);

    fetchProblem().then((res: any) => {
      const {data} = res.data;

      let resultState = data;

      data.forEach(function(partner: any, idPartner: any) {

        if (partner.partner === activePartner){
          const newState = Object.assign({}, partner.data)

          partner.data.forEach(function (item: any, i: any) {
            newState[i] = {
              ...item,
              disabled: item.id_problem === problem.data.id_problem,
              isLeaf: item.has_child_problems <= 0,
              title: item.problem_name,
              key: `${item.id_problem}`
            }

            item.id_problem === problem.data.problem_id_problem && resetKey([item.id_problem],item.id_problem)
          });

          resultState = Object.values(newState);
        }
      });

      setProblems(resultState);
      setLoading(false);
    })

  },[activePartner]);

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <Form.Item label={strings.name} className={styles.name} >
        {getFieldDecorator("name", {
          initialValue: problem.data.problem_name,
          rules: [
            { required: true, message: strings.problemName },
          ],
        })(
          <Input
            placeholder={strings.name}
          />
        )}
      </Form.Item>
      <Form.Item label={strings.sellerName} className={styles.name} >
        {getFieldDecorator("partner", {
          initialValue: problem.data.partner_id_partner,
          rules: [
            { required: true, message: strings.sellerName  },
          ],
        })(
          <Select placeholder={strings.changeSeller} onChange={(e) => {
            resetKey([], null)
            setActivePartner(e)
          }}>
            {
              config.list_partner.map((item: any) =>
                <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>
              )
            }
          </Select>
        )}
      </Form.Item>
      <Form.Item label={strings.parentProblem}>
        {getFieldDecorator("parentProblem", {
          initialValue: problem.data.problem_id_problem,
        })(
          loading
            ? <Skeleton active />
            :
            <Tree
              multiple={true}
              checkable
              checkStrictly={true}
              loadData={onLoadData}
              onCheck={(e: any, b) => resetKey(e.checked, b.node.props.dataRef.id_problem)}
              checkedKeys={problemIdProblem.checked}
            >
              {renderTreeNodes(problems)}
            </Tree>
        )}
      </Form.Item>
      <Triggers 
        problem={problem}
        formula={formula} 
        setFormula={setFormula} 
        tree={tree} 
        setTree={setTree} 
        formulaRemoval={formulaRemoval} 
        setFormulaRemoval={setFormulaRemoval} 
        treeRemoval={treeRemoval} 
        setTreeRemoval={setTreeRemoval} 
      />

      <Form.Item className={styles.name}>
        <Button type="primary" htmlType="submit" block size="large" loading={loading}>
          {strings.save}
        </Button>
      </Form.Item>
    </Form>
  )
})

const SingleProblem = (props: any) => {

  const [problem, setProblem] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {setCurrentProblem} = props;

  useEffect(() => {
    const {idProblem} = props;

    getProblem(idProblem, {})
      .then((res) => {
        setProblem(res.data);
        setCurrentProblem(res.data)
        setLoading(false)
      } )
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });
  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <ProblemForm {...props} problem={problem} />
  )
}

export default SingleProblem
