export const PROBLEM_REQUEST = "PROBLEM_REQUEST"
export const PROBLEM_FAILURE = "PROBLEM_FAILURE"
export const PROBLEM_SUCCESS = "PROBLEM_SUCCESS"

export const PROBLEM_ADD = "PROBLEM_ADD"
export const PROBLEM_EDIT = "PROBLEM_EDIT"
export const PROBLEM_DELETE = "PROBLEM_DELETE"

export interface IProblem {
  id_problem: string
  problem_name: string
}

export interface IProblems {
  data: Array<any>
}

export interface IProblemState {
  loading: boolean
  data: {
    [key: string]: IProblem
  }
  order: Array<string>
  total?: number
}

export interface IProblemAction {
  type: string
  payload?: IProblem[] | IProblem| { id_problem: string, problem_name?: string }
  total?: number
}
