import client from "./client"

import { IApiResponse } from "api/types"
import { IProblem, IProblems } from 'redux/problem/types'


export function fetchProblemAll(): Promise<IApiResponse<IProblems>> {
  return client.get("problems-all")
}

export function fetchProblem(): Promise<IApiResponse<IProblems>> {
  return client.get("problems")
}

export function getProblem(id: string, payload: object): Promise<IApiResponse<IProblem>> {
  return client.get(`problem/${id}`)
}

export function addProblem(payload: object): Promise<IApiResponse<IProblem>> {
  return client.post("/problem", payload)
}

export function editProblem(id: string, payload: object): Promise<IApiResponse<IProblem>> {
  return client.put(`/problem/${id}`, payload)
}

export function deleteProblem(id: string): Promise<null> {
  return client.delete(`/problem/${id}`)
}

export function addTrigger(payload: object): Promise<null> {
  return client.post("/problem-trigger", payload)
}

export function deleteTrigger(id: string): Promise<null> {
  return client.delete(`/problem-trigger/${id}`)
}

export function editTrigger(id: string, payload: object): Promise<null>{
  return client.put(`/problem-trigger/${id}`, payload)
}

export function fetchHistory(page: number, columnSort: any, filter: any): Promise<null> {
  return client.get(`history?page=${page}&field=${columnSort.field}&order=${columnSort.order}&filter=${filter.partner_id_partner}`)
}