import client from "./client"


export function fetchFactor(): Promise<any>{
  return client.get("factors")
}

export function getFactor(id: string, payload: object): Promise<null>{
  return client.get(`factor/${id}`)
}

export function addFactor(payload: object): Promise<null> {
  return client.post("/factor", payload)
}

export function editFactor(id: string, payload: object): Promise<null> {
  return client.put(`/factor/${id}`, payload)
}

export function deleteFactor(id: string): Promise<null> {
  return client.delete(`/factor/${id}`)
}