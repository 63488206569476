import client from "./client"


export function fetchSupport(page: number): Promise<any>{
  return client.get(`supports?page=${page}`)
}

export function getSupport(id: string, payload: object): Promise<null>{
  return client.get(`support/${id}`)
}

export function addSupport(payload: object): Promise<null> {
  return client.post("/support", payload)
}

export function editSupport(id: string, payload: object): Promise<null> {
  return client.put(`/support/${id}`, payload)
}

export function deleteSupport(id: string): Promise<null> {
  return client.delete(`/support/${id}`)
}