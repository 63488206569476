import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Tooltip, Table} from "antd"

import moment from "moment";
import _ from "lodash";

import Page from "shared/components/Page"
import { fetchHistory} from "../../api/problem";
import useSelector from "../../shared/hooks/useSelector"
import {configAction} from "../../redux/config/actions";
import {useDispatch} from "react-redux";
import { strings } from "utils/localization";

interface HistoryProps { history: any}

function History({...props}: HistoryProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)

  const defaultSorter = {
    field: "id_problem_history",
    order: "descend"
  }

  const [columnSort, setColumnSort] = useState(defaultSorter);
  const [activeFilter, setActiveFilter] = useState({partner_id_partner: ""});
  const [filterPartner, setFilterPartner] = useState([]);

  useEffect(() => {
    let partners: any = []
    config.list_partner.map((item: any, key: any) => partners[key] = {text: item.name, value: item.id_partner})
    setFilterPartner(partners)
  }, [config.list_partner]);

  const columns = [
    {
      title: strings.description,
      dataIndex: 'problem_justification',
    },
    {
      title: strings.dateBegin,
      dataIndex: 'date_start',
      sortOrder: columnSort.field === "date_start" && columnSort.order,
      sorter: true,
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    },
    {
      title: strings.dateBegin,
      dataIndex: 'date_stop',
      sortOrder: columnSort.field === "date_stop" && columnSort.order,
      sorter: true,
      render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
    },
    {
      title: strings.problem,
      dataIndex: 'problem_id_problem',
      render: (render: number, row: any) => <Button type="link" onClick={() => gotoProblem(render)}>{row.problem.problem_name}</Button>
    },
    {
      title: strings.sellerName,
      dataIndex: 'partner_id_partner',
      sortOrder: columnSort.field === "partner_id_partner" && columnSort.order,
      sorter: true,
      filters: filterPartner,
      //onFilter: (value: any, record: any) => console.log(value),
      render: (render: number) => _.find(config.list_partner, {id_partner: render}).name
    }
  ] as any;

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({
    total: 10,
    page: 1
  });

  const gotoProblem = React.useCallback((idProblem) => {
    const {history} = props;

    history.push({pathname: `/problems/${idProblem}`});
  },[]);

  const changePage = useCallback(
    (e, filter, sorter) => {
      setPagination({...pagination, page: e.current})
      setColumnSort({ field: sorter.field, order: sorter.order });
      if (sorter.order === undefined) {
        setColumnSort(defaultSorter)
      }
      setActiveFilter(filter)
      if (!filter.partner_id_partner) {
        setActiveFilter({partner_id_partner: ""})
      }
    },
    [columnSort, pagination, activeFilter]
  );

  useEffect(() => {
    dispatch(configAction());
  }, []);

  useEffect(() => {
    setLoading(true);

    fetchHistory(pagination.page, columnSort, activeFilter)
      .then((res: any) => {
        const {dataModels} = res.data.data;
        const {total} = res.data

        setLoading(false);
        setPagination({...pagination, total: total});
        setHistory(dataModels);
      })
      .catch(() => setLoading(false))

  },[pagination.page, columnSort]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: false,
          title: strings.problemHistory,
          actionButtons: []
        }}
      >
        <Table
          loading={loading}
          size="small"
          onChange={(e:any, filter, sorter) => changePage(e, filter, sorter)}
          pagination={{
            total: pagination.total,
            current: pagination.page
          }}
          rowKey={(record: any) => record.id_problem_history}
          columns={columns}
          dataSource={history}
        />
      </Page>
    </>
  )
}

export default History
