import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Button, Tree, Skeleton, Typography,  } from "antd"

import _ from "lodash";

import useSelector from "../../../../shared/hooks/useSelector"
import {editPullRequest, getPullRequest} from "../../../../api/pullRequest";

interface ProblemProps { history: any, contextPullRequests: any, contextLoading: any, context: any}

const { TreeNode } = Tree;
const { Title } = Typography;

function CustomTree({...props}: ProblemProps): ReactElement {

  const config = useSelector(({ config }) => config)

  const {history, contextPullRequests, contextLoading, context} = props;
  const contextName = _.find(config.list_context, {id_context: context}).context_name;

  const [loading, setLoading] = useState(contextLoading);
  const [pullRequests, setPullRequests] = useState(contextPullRequests);

  const updatePullRequest = useCallback( (info, parent) => {
    const {id_pull_request} = info.dragNode.props.dataRef;
    const payload = {
      pull_request_id_pull_request: parent
    };

    id_pull_request &&
    editPullRequest(id_pull_request, payload).then((res: any) => {
      //console.log(res)
    }).catch((err: any) => {
      //console.log(err)
    });

  },[]);

  const onLoadData = useCallback((treeNode) =>
    getPullRequest(treeNode.props.id_pull_request, treeNode.props.title).then((res: any) => {

      const {data} = res.data;
      const newState = Object.assign({}, data.child_pull_request)

      data.child_pull_request.forEach(function (item: any, i: number) {
        newState[i] = {
          ...item,
          isLeaf: item.child_pull_request.length <= 0,
          title: item.button_text,
          key: `${data.id_pull_request}-${item.id_pull_request}`
        }
      });

      treeNode.props.dataRef.children = Object.values(newState);
      setPullRequests([...pullRequests]);

    }).catch((err) => console.log(err))
  ,[pullRequests])

  const renderTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });

  const onDragEnter = (info: any) => {
    //console.log("onDragEnter", info);
    // expandedKeys 需要受控时设置
    // this.setState({
    //   expandedKeys: info.expandedKeys,
    // });
  };

  const onDrop = (info: any) => {

    //console.log("onDrop", info);
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data: any[], key: any, callback: (item: any, index: any, arr: any) => void) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr);
        }
        if (item.children) {
          return loop(item.children, key, callback);
        }
      });
    };

    const data = [...pullRequests];
    let parent = info.node.props.dataRef.id_pull_request;

    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
      if (!info.dropToGap) dragObj = {...item, pull_request_id_pull_request: info.node.props.dataRef.id_pull_request};
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item: { children: any[], pull_request_id_pull_request: any }) => {
        item.children = item.children || [];
        // where to insert 示例添加到尾部，可以是随意位置
        item.children.push(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 && // Has children
      info.node.props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, item => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        ar[index] = {...ar[index], pull_request_id_pull_request: null};
        i = index;
      });
      if (dropPosition === -1) {
        // @ts-ignore
        ar.splice(i, 0, dragObj);
        parent = dropPos.length >= 3 ? info.node.props.dataRef.pull_request_id_pull_request : null;
      } else {
        // @ts-ignore
        ar.splice(i + 1, 0, dragObj);
        parent = dropPos.length >= 3 ? info.node.props.dataRef.pull_request_id_pull_request : null;
      }
    }

    updatePullRequest(info, parent);
    setTimeout( () => setPullRequests(data), 10)
  };

  // @ts-ignore
  // @ts-ignore
  return (
    loading
      ? <Skeleton active />
      :
      <>
      <Title level={4} style={{fontSize: 14}}>{contextName}</Title>
        <Tree
          className="draggable-tree"
          loadData={onLoadData}
          //draggable={true}
          blockNode={false}
          showLine={true}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          onClick={(e, i) => history.push({pathname: `/pull-requests/${i.props.dataRef.id_pull_request}`})}
        >
          {renderTreeNodes(pullRequests)}
        </Tree>
      </>
  )
}

export default CustomTree
