export const CONFIG = "CONFIG"
export const CONFIG_FAILURE = "CONFIG_FAILURE"
export const CONFIG_SUCCESS = "CONFIG_SUCCESS"

export interface IConfigState {
  list_factor: any
  list_condition: any
  list_period_link: any
  list_period: any
  list_partner: any
  list_unit: any
  list_button_type: any
  list_type_problem_behaviors: any
  list_cluster: any
  list_context: any
  list_support_status_type: any
  list_factor_type: any
}

export interface IConfigStateLoadable extends IConfigState {
  loading: boolean
}

export interface IConfigAction {
  type: string,
  payload?: IConfigState
}
