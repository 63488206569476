import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Button, Tree, Skeleton, Spin } from "antd"

import Page from "shared/components/Page"
import {editProblemAction, fetchProblemAction} from "../../../../redux/problem/actions"
import {useDispatch} from "react-redux";
import {editProblem, fetchProblem, getProblem} from "../../../../api/problem";
import {loadState} from "../../../../utils/localStorage";
import CustomTree from "./CustomTree";

interface ProblemProps { history: any}

const { TreeNode } = Tree;

function ListProblem({...props}: ProblemProps): ReactElement {

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [problems, setProblems] = useState([]);

  useEffect(() => {
    fetchProblem().then((res: any) => {
      const {data} = res.data;

      let resultState = data;

      data.forEach(function(partner: any, idPartner: any) {
        const newState = Object.assign({}, partner.data)

        partner.data.forEach(function (item: any, i: any) {
          newState[i] = {
            ...item,
            isLeaf: item.has_child_problems <= 0,
            title: item.problem_name,
            key: `${item.id_problem}`
          }
        });

        resultState[idPartner] = {...resultState[idPartner], data: Object.values(newState)};
      })

      setProblems(resultState);
      setLoading(false);
    })

  },[]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    loading
      ? <Skeleton active />
      : problems.map((item:any) => item.data.length > 0 &&
        <CustomTree key={item.partner} partner={item.partner} history={history} partnerProblems={item.data} partnerLoading={loading}/>
      )
  )
}

export default ListProblem
