import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tree, Input, Spin, Form, Tag, Col, Skeleton, message, Select, Row, Modal, Checkbox} from "antd"

import {useDispatch} from "react-redux";
import styles from "../../../Problems/problems.module.less";
import {FormComponentProps} from "antd/es/form";


import NotFound from "../../../NotFound";
import useSelector from "../../../../shared/hooks/useSelector"
import {
  editPullRequest,
  fetchAllPullRequest,
  fetchPullRequest,
  fetchTags,
  getPullRequest
} from "../../../../api/pullRequest";
import EmojiBlock from "shared/components/Emoji";
import { strings } from "utils/localization";

const { TextArea } = Input;
const { TreeNode } = Tree;
const { Option } = Select;

const PullRequestForm = Form.create({ name: "pullRequest" })((props) => {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {form} : FormComponentProps = props;
  const {pullRequest} : any = props;

  const [activeContext, setActiveContext] = useState(pullRequest.data.context_id_context);
  const [allPullRequest, setAllPullRequest] = useState([]);
  const [loadingAllPullRequest, setLoadingAllPullRequest] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [paramType, setParamType] = useState('');
  const [paramValue, setParamValue] = useState('');
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(true);

  const [cursorPosition, setCursorPosition] = useState( pullRequest.data.text.length);

  const [pullRequests, setPullRequests] = useState([
    { key: -1},
  ]);

  const [hasRedirect, setHasRedirect] = useState(!!pullRequest.data.redirect_id_pull_request);

  const [loading, setLoading] = useState(true);
  const [pullRequestIdPullRequest, setPullRequestIdPullRequest] = useState({
    checked: [],
    idParentPullRequest: pullRequest.data.pull_request_id_pull_request
  });

  const handleSubmit = React.useCallback(
    e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          setLoading(true);

          const payload = {
            button_text: values.name,
            text: values.text,
            synonyms: values.synonyms,
            additional_pull_request: values.parentAdditionalPullRequest ? values.parentAdditionalPullRequest : [],
            pull_request_id_pull_request: pullRequestIdPullRequest.idParentPullRequest,
            redirect_id_pull_request: values.redirectPullRequest ? values.redirectPullRequest : null,
            button_type: values.buttonType,
            context_id_context: values.context,
          };

          editPullRequest(pullRequest.data.id_pull_request, payload)
            .then((res) => {
              setLoading(false);
              message.success('Данные успешно изменены');
              window.history.back()
            })
            .catch((err) => {
              message.error('Ошибка изменения данных');
              setLoading(false)
            })
        }
      })
    },
    [form, dispatch, pullRequestIdPullRequest, loading]
  )

  const { getFieldDecorator } = form

  const resetKey = useCallback((e, id) => {
      const arr: any = [e[e.length - 1]];
      setPullRequestIdPullRequest({
        checked: arr,
        idParentPullRequest: arr[0] ? id : null
      });
      console.log({
        checked: arr,
        idParentPullRequest: arr[0] ? id : null
      })
    },[pullRequestIdPullRequest]);

  const onLoadData = useCallback((treeNode) =>
      getPullRequest(treeNode.props.id_pull_request, treeNode.props.title).then((res: any) => {

        const {data} = res.data;
        const newState = Object.assign({}, data.child_pull_request)

        const loop = (data: any[], key: any, callback: (item: any, index: any, arr: any) => void) => {
          data.forEach((item, index, arr) => {
            if (item.id_pull_request === key && item.disabled) {
              return callback(item, index, arr);
            }
            if (item.children) {
              return loop(item.children, key, callback);
            }
          });
        };

        data.child_pull_request.forEach(function (item: any, i: number) {
          let disable = false;

          loop(pullRequests, data.id_pull_request, itemp => {
            itemp.children = itemp.children || [];
            itemp.disabled = true;
            disable = true;
          });

          newState[i] = {
            ...item,
            disabled: disable || pullRequest.data.id_pull_request === item.id_pull_request,
            isLeaf: item.child_pull_request.length <= 0,
            title: item.button_text,
            key: `${data.id_pull_request}-${item.id_pull_request}`
          };

          item.id_pull_request === pullRequest.data.pull_request_id_pull_request && resetKey([`${data.id_pull_request}-${item.id_pull_request}`],item.id_pull_request)
        });

        treeNode.props.dataRef.children = Object.values(newState);
        setPullRequests([...pullRequests]);

      }).catch((err) => console.log(err))
    ,[pullRequests, pullRequest]);

  const renderTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            disabled={item.id_pull_request === pullRequest.data.id_pull_request || item.disabled}
            title={item.title} key={item.key} dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} dataRef={item} />;
    });

  useEffect(() => {

    setLoading(true);
    setLoadingTags(true);

    fetchPullRequest().then((res: any) => {
      const {data} = res.data;

      let resultState = data;

      data.forEach(function(context: any, idContext: any) {

        if (context.context === activeContext){
          const newState = Object.assign({}, context.data)

          context.data.forEach(function (item: any, i: any) {
            newState[i] = {
              ...item,
              disabled: item.id_pull_request === pullRequest.data.id_pull_request,
              isLeaf: item.has_child_pull_request <= 0,
              title: item.button_text,
              key: `${item.id_pull_request}`
            }

            item.id_pull_request === pullRequest.data.pull_request_id_pull_request && resetKey([item.id_pull_request],item.id_pull_request)
          });

          resultState = Object.values(newState);
        }
      });

      setPullRequests(resultState);
      setLoading(false);

    })

    fetchTags().then((res: any) => {
      const {data} = res.data;
      setTags(data);
      setLoadingTags(false);
    })

    fetchAllPullRequest().then((res: any) => {
      const {data} = res.data;
      setAllPullRequest(data);
      setLoadingAllPullRequest(false)
    })

  },[activeContext]);

  const changeText = useCallback((e, param = '') => {
    const {getFieldsValue, setFieldsValue} = form;
    let values = getFieldsValue();
    const tag = `<${e}${param}>`;

    const newText = `${values.text.slice(0,cursorPosition)}${tag}${values.text.slice(cursorPosition,values.text.length)}`;

    values = {...values, text: newText};
    setFieldsValue(values)
  },[cursorPosition]);

  const clickTag = useCallback((item) => {
    if (item.name === 'link') {
      setParamType(item.name)
      setVisibleModal(true)
    }else{
      changeText(item.name)
    }
  },[cursorPosition]);

  return (
    <>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <Form.Item label={strings.buttonName} className={styles.name} >
          {getFieldDecorator("name", {
            initialValue: pullRequest.data.button_text,
            rules: [
              { required: true, message: strings.buttonName },
            ],
          })(
            <Input
              placeholder={strings.buttonName}
              addonAfter={<EmojiBlock form={form} />}
            />
          )}
        </Form.Item>
        <Form.Item label={strings.context} className={styles.name} >
          {getFieldDecorator("context", {
            initialValue: pullRequest.data.context_id_context,
            rules: [
              { required: true, message: strings.context },
            ],
          })(
            <Select placeholder={strings.context} onChange={(e) => {
              resetKey([], null)
              setActiveContext(e)
            }}>
              {config.list_context.map((item: any, key: any) => <Option key={key} value={item.id_context}>{item.context_name}</Option>)}
          </Select>
          )}
        </Form.Item>
        <Form.Item label={strings.buttonType} className={styles.name} >
          {getFieldDecorator("buttonType", {
            initialValue: pullRequest.data.button_type,
            rules: [
              { required: true, message: strings.buttonType },
            ],
          })(
            <Select placeholder={strings.buttonType}>
              {config.list_button_type.map((item: any, key: any) => <Option key={key} value={item.id}>{item.title}</Option>)}
          </Select>
          )}
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={strings.parentAnswer}>
              {getFieldDecorator("parentPullRequest", {
                initialValue: pullRequest.data.pull_request_id_pull_request,
              })(
                loading
                  ? <Skeleton active />
                  :
                  <Tree
                    multiple={true}
                    checkable
                    checkStrictly={true}
                    loadData={onLoadData}
                    onCheck={(e: any, b) => resetKey(e.checked, b.node.props.dataRef.id_pull_request)}
                    checkedKeys={pullRequestIdPullRequest.checked}
                  >
                    {renderTreeNodes(pullRequests)}
                  </Tree>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className={styles.name}>
              <Checkbox checked={hasRedirect} onChange={(e) => setHasRedirect(e.target.checked)} >{strings.hasRedirect}</Checkbox>
            </Form.Item>
            {hasRedirect ?
              <Form.Item label={strings.redirectAnswer} className={styles.name}>
                {!loadingAllPullRequest
                  ? getFieldDecorator("redirectPullRequest", {
                      initialValue: pullRequest.data.redirect_id_pull_request,
                    })(
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Выберите ответ"
                        onChange={() => console.log(123)}
                      >
                        {
                          allPullRequest.map((item: any) => <Option disabled={item.id_pull_request === pullRequest.data.id_pull_request} value={item.id_pull_request} key={item.id_pull_request}>{item.button_text}</Option>)
                        }
                      </Select>)
                  : <Skeleton active />
                }
              </Form.Item>
              :
              <Form.Item label={strings.additionalAnswer} className={styles.name}>
                {!loadingAllPullRequest
                  ? getFieldDecorator("parentAdditionalPullRequest", {
                      initialValue: Array.from(pullRequest.data.child_additional_pull_request, (e: any) => e.id_pull_request),
                    })(
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Выберите ответы"
                        onChange={() => console.log(123)}
                      >
                        {
                          allPullRequest.map((item: any) => <Option disabled={item.id_pull_request === pullRequest.data.id_pull_request} value={item.id_pull_request} key={item.id_pull_request}>{item.button_text}</Option>)
                        }
                      </Select>)
                  : <Skeleton active />
                }
              </Form.Item>
            }
          </Col>
        </Row>

        <Form.Item label={strings.synonyms} >
          <Row gutter={16}>
            <Col span={12}>
              {getFieldDecorator("synonyms", {
                initialValue: pullRequest.data.synonyms,
                rules: [
                  { required: true, message: strings.synonyms },
                ],
              })(
                <TextArea
                  rows={4}
                  placeholder={strings.synonyms}
                />
              )}
            </Col>
            <Col span={12} className={styles.italic}>
              {strings.synonymsDescr}
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label={strings.textAnswer} >
          <Row gutter={16}>
            <Col span={12}>
              {getFieldDecorator("text", {
                initialValue: pullRequest.data.text,
                rules: [
                  { required: true, message: strings.textAnswer },
                ],
              })(
                <TextArea
                  onBlur={(e) => {
                    setCursorPosition(e.target.selectionStart)
                  }}
                  rows={4}
                  placeholder={strings.textAnswer}
                />
              )}
            </Col>
            <Col span={12}>
              {
                !loadingTags
                  ? tags.map((item: any) =>
                    <Tag key={item.id_tag} onClick={() => clickTag(item)}>
                      {item.title}
                    </Tag>)
                  : <Skeleton active />
              }
            </Col>
          </Row>
        </Form.Item>

        <Form.Item className={styles.name}>
          <Button type="primary" htmlType="submit" block size="large" loading={loading}>
            {strings.save}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={strings.addParametr}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            changeText(paramType, `#${paramValue}`);
            setParamValue('');
            setVisibleModal(false)
          }}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.value}>
          <Input value={paramValue} onChange={(e: any) => setParamValue(e.target.value)} />
        </Form.Item>
      </Modal>
    </>
  )
})

const SinglePullRequest = (props: any) => {

  const [pullRequest, setPullRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const {idPullRequest, setCurrentPullRequest} = props;

    getPullRequest(idPullRequest, {})
      .then((res: any) => {
        setPullRequest(res.data);
        setCurrentPullRequest(res.data);
        setLoading(false)
      } )
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false)
      });
  }, []);

  return (
    loading ? <Spin spinning={loading} /> : error ? <NotFound /> :
    <PullRequestForm {...props} pullRequest={pullRequest} />
  )
}

export default SinglePullRequest
