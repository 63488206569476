import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message, Select} from "antd"

import Page from "shared/components/Page"
import SingleProblem from "./screens/SingleProblem";
import ListProblem from "./screens/ListProblem";
import {addProblem, deleteProblem} from "../../api/problem";

import {configAction} from "../../redux/config/actions";
import {useDispatch} from "react-redux";
import useSelector from "../../shared/hooks/useSelector"
import { strings } from "utils/localization";

const { Option } = Select;

interface ProblemProps { history: any}

function Problem({...props}: ProblemProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {pathname} = props.history.location;
  const idProblem = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [problemName, setProblemName] = useState('');
  const [problemPartner, setProblemPartner] = useState(0);
  const [currentProblem, setCurrentProblem] = useState({data: {problem_name: ''}});
  const [key, setKey] = useState(1);

  const addNewProblem = useCallback(() => {

    setProblemName('');
    setProblemPartner(0);
    setLoading(true);
    const payload = {
      problem_name: problemName,
      partner_id_partner: problemPartner
    };

    addProblem(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[problemName, problemPartner, key, visibleModal, loading]);

  const deleteCurrentProblem = useCallback(() => {

    setLoading(true);

    deleteProblem(idProblem)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idProblem]);

  useEffect(() => {
    //console.log(problem.data)
    dispatch(configAction());
  }, []);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idProblem,
          title: idProblem ? currentProblem.data.problem_name : strings.problems,
          actionButtons: !idProblem ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addProblem}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentProblem()}>
              {strings.removeProblem}
            </Button>,
          ],
        }}
      >
        {idProblem
          ?
            <SingleProblem {...props} idProblem={idProblem} setCurrentProblem={setCurrentProblem} />
          :
            <ListProblem key={key} {...props} />
        }
      </Page>
      <Modal
        title={strings.appendProblem}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!problemName || !problemPartner)} onClick={() => addNewProblem()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.problemName}>
          <Input value={problemName} onChange={(e: any) => setProblemName(e.target.value)} />
        </Form.Item>
        <Form.Item label={strings.sellerName}>
          <Select placeholder={strings.changeSeller} onChange={(e: any) => setProblemPartner(e)} style={{width: '100%'}}>
            {
              config.list_partner.map((item: any) =>
                <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>
              )
            }
          </Select>
        </Form.Item>
      </Modal>
    </>
  )
}

export default Problem
