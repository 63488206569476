import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Table, Tooltip, Button} from "antd"

import moment from "moment";
import _ from "lodash";

import useSelector from "../../../../shared/hooks/useSelector"
import {useDispatch} from "react-redux";

import {fetchPushTemplate} from "../../../../api/pushTemplate";
import { strings } from "utils/localization";

interface PushTemplatetProps { history: any}

function ListPushTemplate({...props}: PushTemplatetProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)

  const columns = [
    {
      dataIndex: 'id_push_template',
      render: (render: any) => (
        <Tooltip title="Редактирование">
          <Button
            type="link"
            onClick={e => history.push({pathname: `/push-template/${render}`})}
            shape="circle"
            icon="edit"
          />
        </Tooltip>
      )
    },
    {
      title: strings.periodSend,
      dataIndex: 'time_start',
      render: (render: any, row: any) => row.time_start && row.time_stop && `${strings.from} ${moment(row.time_start, "HH:mm").format("HH:mm")} ${strings.to} ${moment(row.time_stop, "HH:mm").format("HH:mm")}`
    },
    {
      title: strings.context,
      dataIndex: 'context_id_context',
      render: (render: number, row: any) => <Button type="link" onClick={() => gotoContext(render)}>{_.find(config.list_context, {id_context: render}).context_name}</Button>
    },
    {
      title: strings.sellerName,
      dataIndex: 'partner_id_partner',
      render: (render: number) => _.find(config.list_partner, {id_partner: render}).name
    }
  ];

  const {history} = props;

  const [loading, setLoading] = useState(true);
  const [pushTemplates, setPushTemplates] = useState([]);

  const gotoContext = React.useCallback((idContext) => {
    const {history} = props;

    history.push({pathname: `/context/${idContext}`});
  },[]);

  useEffect(() => {
    fetchPushTemplate().then((res) => {
      const {dataModels} = res.data.data;

      setPushTemplates(dataModels);
      setLoading(false);
    })

  },[]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
    <Table
      loading={loading}
      size="small"
      rowKey={(record: any) => record.id_push_template}
      columns={columns}
      dataSource={pushTemplates}
    />
  )
}

export default ListPushTemplate
