import React, {ReactElement} from "react"

import {Tabs} from "antd"

import _ from "lodash";

import Page from "shared/components/Page"
import HistoryPushRequestMessage from "./components/Messages";
import HistoryPushRequestProblem from "./components/Problems";
import HistoryPushRequestRecommendation from "./components/Recommendations";

import useSelector from "../../shared/hooks/useSelector"
import { strings } from "utils/localization";

const { TabPane } = Tabs;

interface HistoryProps { history: any}

function HistoryPushRequest({...props}: HistoryProps): ReactElement {

  const config = useSelector(({ config }) => config)

  const {history} = props

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: false,
          title: strings.pushHistory,
          actionButtons: []
        }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={strings.messages} key="1">
            <HistoryPushRequestMessage history={history} config={config}/>
          </TabPane>
          <TabPane tab={strings.problems} key="2">
            <HistoryPushRequestProblem history={history} config={config}/>
          </TabPane>
          <TabPane tab={strings.recommendations} key="3">
            <HistoryPushRequestRecommendation history={history} config={config}/>
          </TabPane>
        </Tabs>
      </Page>
    </>
  )
}

export default HistoryPushRequest
