import {
  IConfigStateLoadable,
  IConfigAction,
  CONFIG_FAILURE,
  CONFIG,
  CONFIG_SUCCESS,
} from "./types"

const initialState: IConfigStateLoadable = {
  list_factor: [],
  list_condition: [],
  list_period_link: [],
  list_period: [],
  list_partner: [],
  list_unit: [],
  list_button_type: [],
  list_type_problem_behaviors: [],
  list_cluster: [],
  list_context: [],
  list_support_status_type: [],
  list_factor_type: [],
  loading: false,
}

export default (state = initialState, action: IConfigAction): IConfigStateLoadable => {
  switch (action.type) {
    case CONFIG:
      return { ...state, loading: true }
    case CONFIG_SUCCESS:
      if (action.payload) {
        return { ...action.payload, loading: false }
      } else {
        return { ...state, loading: false }
      }
    case CONFIG_FAILURE:
      return { ...state, loading: false }

    default:
      return state
  }
}
