/* eslint-disable */
import React from "react";
import moment from "moment";

/**
 *
 * @param response
 * @returns {*}
 */
export function checkHttpStatus(response) {
  // if success status
  if (response.status.error === 0) {
    return response;
  } else {
    if (response.status === 422) {
      throw new ValidationError(response.data);
    }

    //TODO: so bad code?
    if (response.status.message === "нет такой сессии") {
      localStorage.removeItem("sessionId");
      console.log(response.status.message);
      window.location.replace(`${process.env.PUBLIC_URL}/auth`);
    }

    throw new Error(response.status.message);
  }
}

/**
 *
 * @param response
 * @returns {*}
 */
export function parseJSON(response) {
  return response.json();
}
