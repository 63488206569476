import React, {ReactElement, useEffect, useState, useCallback} from "react"

import { Button, Tree, Skeleton, Tooltip, Table, Badge } from "antd"

import {fetchPushRequest} from "../../../../api/pushRequest";
import moment from "moment";
import _ from "lodash";
import { strings } from "utils/localization";

interface ProblemProps { history: any, config: any}

const { TreeNode } = Tree;

function ListPushRequest({...props}: ProblemProps): ReactElement {

  const {history, config} = props;

  const columns = [
    {
      dataIndex: 'id_push_request',
      render: (render: any) => (
        <Tooltip title="Редактирование">
          <Button
            type="link"
            onClick={e => history.push({pathname: `/push-requests/${render}`})}
            shape="circle"
            icon="edit"
          />
        </Tooltip>
      )
    },
    {
      dataIndex: 'is_active',
      render: (render: number) => 
        render ? 
          <Tooltip title='Активен'>
            <Badge status="success" />
          </Tooltip>
        : 
          <Tooltip title='Неактивен'>
            <Badge status="error" />
          </Tooltip>
    },
    {
      title: strings.textMessage,
      dataIndex: 'text',
    },
    {
      title: strings.condition,
      dataIndex: 'problem_id_problem',
      render: (render: number, row: any) => 
        <>
          <span>
            {strings.problem}
          </span>
          <Button style={{padding: 4}} type="link" onClick={() => gotoProblem(render)}>
            {row.problem.problem_name}
          </Button>
          <span style={{textTransform: 'lowercase'}}>
            {_.find(config.list_type_problem_behaviors, {id: row.problem_behaviors}).title}
          </span>
        </>
    },
    {
      title: strings.periodSend,
      dataIndex: 'time_start',
      render: (render: any, row: any) => row.time_start && row.time_stop && `${strings.from} ${moment(row.time_start, "HH:mm").format("HH:mm")} ${strings.to} ${moment(row.time_stop, "HH:mm").format("HH:mm")}`
    },
    {
      title: strings.partner,
      dataIndex: 'partner_id_partner',
      render: (render: number) => _.find(props.config.list_partner, {id_partner: render}).name
    },

  ];

  const [loading, setLoading] = useState(true);
  const [pushRequests, setPushRequests] = useState([]);
  const [pagination, setPagination] = useState({
    total: 10,
    page: 1
  });

  const gotoProblem = React.useCallback((idProblem) => {
    const {history} = props;

    history.push({pathname: `/problems/${idProblem}`});
  },[]);

  useEffect(() => {
    setLoading(true);

    fetchPushRequest(pagination.page)
      .then((res: any) => {
        const {data,total} = res.data;

        setPagination({...pagination, total: total});
        setPushRequests(data);

        setLoading(false);
      })
      .catch(() => setLoading(false))

  },[pagination.page]);

  useEffect(() => {
    //console.log(loading)
  },[loading])

  // @ts-ignore
  // @ts-ignore
  return (
      <Table
        loading={loading}
        size="small"
        onChange={(e:any) => setPagination({...pagination, page: e.current})}
        pagination={{
          total: pagination.total,
          current: pagination.page
        }}
        rowKey={(record: any) => record.id_push_request}
        columns={columns}
        dataSource={pushRequests}
      />
  )
}

export default ListPushRequest
