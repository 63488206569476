import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tooltip, Table, Badge, Row} from "antd"

import { fetchHistory, fetchDetailHistory} from "../../../api/pushRequest";

import {configAction} from "../../../redux/config/actions";
import {useDispatch} from "react-redux";
import moment from "moment";
import _ from "lodash";
import { strings } from "utils/localization";

interface HistoryProps { history: any, config: any}

function HistoryPushRequestMessage({...props}: HistoryProps): ReactElement {

    const dispatch = useDispatch()
    const {config} = props
  
    const columns = [
      {
        title: strings.externalQuestionId,
        dataIndex: 'id_external_question',
      },
      {
        title: strings.dateCreate,
        dataIndex: 'created_at',
        render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
      },
      {
        title: strings.periodSend,
        dataIndex: 'template',
        render: (render: any) => render.time_start && render.time_stop && <Button type="link" onClick={() => gotoTemplate(render.id_push_template)}>{strings.from} {moment(render.time_start, "HH:mm").format("HH:mm")} {strings.to} {moment(render.time_stop, "HH:mm").format("HH:mm")}</Button>
      },
      {
        title: strings.clientFio,
        dataIndex: 'fio',
      },
      {
        title: strings.clientPhone,
        dataIndex: 'phone',
      },
      {
        title: strings.partner,
        dataIndex: 'partner_id_partner',
        render: (render: number) => _.find(props.config.list_partner, {id_partner: render ? render : 20}).name
      },
      {
        title: strings.message,
        dataIndex: 'text',
        render: (render: any, row: any) => `${render}. ${row.score ? `Оценка - ${row.score}⭐` : ``}`
      }
    ];
  
    const columnsDetails = [
      {
        dataIndex: 'status',
        render: (render: number) => 
          render === 1 ? 
            <Tooltip title='В процессе отправки'>
              <Badge status="processing" /> 
            </Tooltip>
          :  
            render === 2 ? 
              <Tooltip title='Отправлен'>
                <Badge status="success" /> 
              </Tooltip>
            :
              <Tooltip title='Ошибка'>
                <Badge status="error" />
              </Tooltip>
      },
      { title: strings.chatId, dataIndex: 'chat_id'},
      {
        title: strings.dateCreate,
        dataIndex: 'created_at',
        render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
      },
      {
        title: strings.dateChange,
        dataIndex: 'updated_at',
        render: (render: any) => render && moment(render, "YYYY-MM-DD HH:mm").format("DD.MM.YYYY HH:mm")
      },
    ];
  
    const [activeIdPushRequestCustom, setActiveIdPushRequestCustom] = useState(0);
    
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [pagination, setPagination] = useState({
      total: 10,
      page: 1
    });
  
    const [loadingDetail, setLoadingDetail] = useState(true);
    const [detail, setDetail] = useState([]);
  
    const gotoTemplate = React.useCallback((idTemplate) => {
      const {history} = props;
  
      history.push({pathname: `/push-template/${idTemplate}`});
    },[]);
  
    const lookDetail = useCallback(
      (val, e) => {
        setActiveIdPushRequestCustom(e.id_push_request_custom);
        if (val) {
          setLoadingDetail(true) 
          fetchDetailHistory(e.id_push_request_custom).then((res: any) => {
  
              const {data} = res.data;
  
              let newDetail:any = detail;
              newDetail[e.id_push_request_custom] = data
  
              setDetail(newDetail);
              setLoadingDetail(false);
              
          }).catch(() => setLoadingDetail(false));
        }
      },
      [activeIdPushRequestCustom]
    );
  
    useEffect(() => {
      dispatch(configAction());
    }, []);
  
    useEffect(() => {
      setLoading(true);
  
      fetchHistory(pagination.page)
        .then((res: any) => {
          const {data} = res.data;
          const {total} = res.data
  
          setPagination({...pagination, total: total});
          setHistory(data);
          setLoading(false);
  
        })
        .catch(() => setLoading(false))
  
    },[pagination.page]);
  
    // @ts-ignore
    // @ts-ignore
    return (
        <Table
            loading={loading}
            size="small"
            onChange={(e:any) => setPagination({...pagination, page: e.current})}
            pagination={{
                total: pagination.total,
                current: pagination.page
            }}
            expandedRowRender={(record, key) => (
                <Row>
                <Table 
                    loading={loadingDetail}
                    bordered={false}
                    columns={columnsDetails} 
                    dataSource={detail[record.id_push_request_custom]} 
                    rowKey={(record: any) => record.chat_id}
                    size="small"
                />
                </Row>
            )}
            onExpand={(e, record) => lookDetail(e, record)}
            rowKey={(record: any) => record.id_push_request_custom}
            columns={columns}
            dataSource={history}
        />
    )
  }
  
  export default HistoryPushRequestMessage