import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Tree, Skeleton, Modal, Form, Input, message, Select, DatePicker, TimePicker} from "antd"

import Page from "shared/components/Page"
import SinglePushRequest from "./screens/SinglePushRequest";
import ListPushRequest from "./screens/ListPushRequest";

import {configAction} from "../../redux/config/actions";
import {useDispatch} from "react-redux";
import useSelector from "../../shared/hooks/useSelector"
import {addPushRequest, deletePushRequest} from "../../api/pushRequest";

import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from "moment";
import "moment/locale/ru";
import { fetchProblem, getProblem, fetchProblemAll } from "api/problem";
import { strings } from "utils/localization";

const { Option } = Select;
const { TreeNode } = Tree;

interface RequestProps { history: any}

function PushRequest({...props}: RequestProps): ReactElement {

  const dispatch = useDispatch()
  const config = useSelector(({ config }) => config)
  const {pathname} = props.history.location;
  const idPushRequest = pathname.split("/")[2];
  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pushRequestText, setPushRequestText] = useState('');
  const [pushRequestPartner, setPushRequestPartner] = useState('');
  const [pushRequestProblem, setPushRequestProblem] = useState('');
  const [pushRequestStartDate, setPushRequestStartDate] = useState('');
  const [pushRequestStopDate, setPushRequestStopDate] = useState('');
  const [key, setKey] = useState(1);
  const [problems, setProblems] = useState([
    { key: -1},
  ]);
  const [loadingProblems, setLoadingProblems] = useState(false);

  const addNewPushRequest = useCallback(() => {

    setPushRequestText('');
    setPushRequestPartner('');
    setPushRequestProblem('');
    setPushRequestStartDate('');
    setPushRequestStopDate('');
    setLoading(true);

    const payload = {
      text: pushRequestText,
      partner_id_partner: pushRequestPartner,
      time_start: moment(pushRequestStartDate).format('HH:mm'),
      time_stop: moment(pushRequestStopDate).format('HH:mm'),
      problem_id_problem: pushRequestProblem
    };

    addPushRequest(payload)
      .then((res) => {
        setKey(Math.random);
        setLoading(false)
        setVisibleModal(false)
        message.success('Данные успешно добавлены');
      })
      .catch((err) => {
        message.error('Ошибка добавления данных');
        setLoading(false)
      })
  },[pushRequestText, pushRequestPartner, pushRequestStartDate, pushRequestStopDate, pushRequestProblem, key, visibleModal, loading]);

  const deleteCurrentPushRequest = useCallback(() => {

    setLoading(true);

    deletePushRequest(idPushRequest)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idPushRequest]);

  useEffect(() => {
    //console.log(problem.data)
    dispatch(configAction());
  }, []);


  useEffect(() => {
    fetchProblemAll().then((res: any) => {
      const {data} = res.data;
      setProblems(data);
      setLoadingProblems(false);
    })
  },[])

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idPushRequest,
          title: idPushRequest ? `${strings.push} №${idPushRequest}` :strings.pushChatbot,
          actionButtons: !idPushRequest ? [
            <Button key="add" type="primary" icon="plus" onClick={() => setVisibleModal(true)}>
              {strings.addPush}
            </Button>,
          ] : [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentPushRequest()}>
              {strings.removePush}
            </Button>,
          ],
        }}
      >
        {idPushRequest
          ?
            <SinglePushRequest {...props} idPushRequest={idPushRequest} />
          :
            <ListPushRequest key={key} {...props} config={config}/>
        }
      </Page>
      <Modal
        title={strings.addedPush}
        visible={visibleModal}
        onCancel={() => setVisibleModal(false)}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {strings.cancel}
          </Button>,
          <Button key="submit" type="primary" loading={loading} disabled={(!pushRequestText || !pushRequestPartner || !pushRequestStartDate || !pushRequestStopDate || !pushRequestProblem )} onClick={() => addNewPushRequest()}>
            {strings.add}
          </Button>
        ]}
      >
        <Form.Item label={strings.periodSend} style={{width: '100%'}}>
          {`${strings.from} `}
          <TimePicker format='HH:mm' placeholder="Дата отправки" value={moment(pushRequestStartDate ? pushRequestStartDate : '00:00', 'HH:mm') } 
            onChange={(_: any, e:any) => setPushRequestStartDate(_)} />
          {` ${strings.to} `}
          <TimePicker format='HH:mm' placeholder="Дата отправки" value={moment(pushRequestStopDate ? pushRequestStopDate : '00:00', 'HH:mm') } 
            onChange={(_: any, e:any) => setPushRequestStopDate(_)} />
        </Form.Item>
        
        <Form.Item label={strings.textPush}>
          <Input value={pushRequestText} onChange={(e: any) => setPushRequestText(e.target.value)} />
        </Form.Item>

        <Form.Item label={strings.partner} >
          <Select style={{width: '100%'}} placeholder="Выберите партнера" value={pushRequestPartner} onChange={(e: any) => {
            setPushRequestPartner(e)
            setPushRequestProblem('')}
          }>
            {config.list_partner.map((item: any) => <Option key={item.id_partner} value={item.id_partner}>{item.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label={strings.problem}>
          <Select style={{width: '100%'}} loading={loadingProblems} value={pushRequestProblem} onChange={(e: any) => setPushRequestProblem(e)}>
            {problems.map((item: any) => (item.partner === pushRequestPartner || item.partner===20) &&
              item.data.map((itemProblem: any) => <Option key={itemProblem.id_problem} value={itemProblem.id_problem}>{itemProblem.problem_name}</Option>))}
          </Select>
        </Form.Item>
      </Modal>
    </>
  )
}

export default PushRequest
