import React, {ReactElement, useEffect, useState, useCallback} from "react"

import {Button, Modal, Form, Input, message} from "antd"

import Page from "shared/components/Page"
import SingleSupport from "./screens/SingleSupport";
import ListSupport from "./screens/ListSupport";
import {deleteSupport} from "../../api/support";
import { strings } from "utils/localization";

interface SupportProps { history: any}

function Support({...props}: SupportProps): ReactElement {

  const {pathname} = props.history.location;
  const idSupport = pathname.split("/")[2];
  const [loading, setLoading] = useState(false);
  const [currentSupport, setCurrentSupport] = useState({data: {text: ''}});

  const deleteCurrentSupport = useCallback(() => {

    setLoading(true);

    deleteSupport(idSupport)
      .then((res) => {
        setLoading(false)
        message.success('Данные успешно удалены');
        window.history.back();
      })
      .catch((err) => {
        message.error('Ошибка удаления данных');
        setLoading(false)
      })
  },[loading, idSupport]);

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Page
        pageHeader={{
          hasBack: !!idSupport,
          title: idSupport ? "Вопрос поддержки №"+idSupport :strings.support,
          actionButtons: idSupport && [
            <Button key="delete" loading={loading} type="danger" icon="delete" onClick={() => deleteCurrentSupport()}>
              Удалить вопрос
            </Button>,
          ],
        }}
      >
        {idSupport
          ?
            <SingleSupport {...props} idSupport={idSupport} setCurrentSupport={setCurrentSupport} />
          :
            <ListSupport key={1} {...props} />
        }
      </Page>
    </>
  )
}

export default Support
