import client from "./client"


export function fetchTag(): Promise<any>{
  return client.get("tags")
}

export function getTag(id: string, payload: object): Promise<null>{
  return client.get(`tag/${id}`)
}

export function addTag(payload: object): Promise<null> {
  return client.post("/tag", payload)
}

export function editTag(id: string, payload: object): Promise<null> {
  return client.put(`/tag/${id}`, payload)
}

export function deleteTag(id: string): Promise<null> {
  return client.delete(`/tag/${id}`)
}